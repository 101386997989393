var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(152, "Type of premise") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.typeOfPremiseLabel, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(153, "Country") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.countryLabel, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(30, "Area premises"),
                    "label-for": "area",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { id: "area", disabled: "" },
                        model: {
                          value: _vm.premise.filmingArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.premise, "filmingArea", $$v)
                          },
                          expression: "premise.filmingArea",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("m"),
                                _c("sup", [_vm._v("2")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", { attrs: { for: "occupation" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(11, "Total no. of days occupation")
                            ) +
                            "  \n\t\t\t\t\t\t"
                        ),
                        _c("info-card", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  40,
                                  "Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable)."
                                )
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { id: "occupation", disabled: "" },
                        model: {
                          value: _vm.premise.totNbOfDaysOccupation,
                          callback: function ($$v) {
                            _vm.$set(_vm.premise, "totNbOfDaysOccupation", $$v)
                          },
                          expression: "premise.totNbOfDaysOccupation",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(49, "days")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", { attrs: { for: "d" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(12, "Total no. of man days")) +
                            "  \n\t\t\t\t\t\t"
                        ),
                        _c("info-card", { attrs: { position: "top-left" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  41,
                                  "Total number of days the property will be occupied times the average number of people expected to be in the space."
                                )
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { id: "days", type: "number", disabled: "" },
                        model: {
                          value: _vm.premise.totNbOfManDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.premise, "totNbOfManDays", $$v)
                          },
                          expression: "premise.totNbOfManDays",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(49, "days")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(17, "No. of air conditionners"),
                    "label-for": "airCond",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { id: "airCond", type: "text", disabled: "" },
                        model: {
                          value: _vm.premise.nbOfAirConditioners,
                          callback: function ($$v) {
                            _vm.$set(_vm.premise, "nbOfAirConditioners", $$v)
                          },
                          expression: "premise.nbOfAirConditioners",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(50, "total")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }