<template>
	<b-modal
		id="viewLogAutomationDialog"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(45, 'Log for change status')"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		ok-only
		ok-variant="primary"
		:ok-title="FormMSG(569, 'Close')"
		@ok.prevent="emitEventClose"
	>
		<div>
			<b-table
				v-if="$screen.width >= 992"
				selected-variant="primary"
				hover
				selectable
				select-mode="single"
				responsive="sm"
				ref="documentPackageTable"
				sticky-header="500px"
				:items="dataList"
				style="text-align: left"
				:fields="greenTableFields"
				bordered
				striped
				small
				head-variant="dark"
				:empty-text="FormMSG(250, 'No data found')"
				show-empty
				tbody-tr-class="p-2"
			>
				<template #cell(status)>
					<div class="wrap-status">
						<div class="status fw-700 not-submitted" style="font-size: 0.7rem">
							<div class="text-center">
								{{ FormMSG(857, 'ERROR') }}
							</div>
						</div>
					</div>
				</template>
			</b-table>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency } from '~helpers';
import moment from 'moment';

export default {
	name: 'LogChangeStatusDialog',

	mixins: [languageMessages],

	props: {
		open: { type: Boolean, default: false, required: false },
		dataList: { type: Array, default: () => [], required: false }
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		greenTableFields() {
			return [
				{
					key: 'strDate',
					label: this.FormMSG(165, 'Date'),
					formatter: (value) => {
						return moment(new Date(value.split('T')[0])).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'w-75-px'
				},
				{
					key: 'entityType',
					label: this.FormMSG(200, 'Source'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(11006, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'coTwoCategory',
					label: this.FormMSG(202, 'CO2 type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1334, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'supplier.name',
					label: this.FormMSG(203, 'Supplier'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(201, 'Description'),
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(207, 'Amount'),
					formatter: (value) => {
						return rendCurrency(parseFloat(value));
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'status',
					label: this.FormMSG(296, 'Status'),
					sortable: false,
					class: 'text-center'
				}
			];
		}
	},

	methods: {
		emitEventClose() {
			this.$emit('log-change-status-dialog:close');
		}
	}
};
</script>
