<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(75, 'New entry options')"
		header-class="header-class-modal-doc-package"
		:size="showReportImport ? 'xl' : 'lg'"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<form ref="containerEntryOptionRef">
			<b-row v-if="stateError.show">
				<b-col>
					<b-alert variant="danger" show dismissible @dismissed="handleDismissedAlert">
						<div v-html="stateError.msg" />
					</b-alert>
				</b-col>
			</b-row>
			<b-form-radio :value="0" v-model="optionChoice">
				<div class="fs-14 fw-700">
					{{ FormMSG(48, 'Without receipt') }}
				</div>
			</b-form-radio>
			<b-form-radio :value="1" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(158, 'Main receipt') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(73, 'Create one entry per receipt/invoice') }}
					</div>
				</div>
			</b-form-radio>
			<b-form-radio :value="2" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(101, 'Detailed import') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(83, 'Create one entry per line of each receipt/invoice') }}
					</div>
				</div>
			</b-form-radio>
			<b-collapse :visible="optionChoice === 1 || optionChoice === 2">
				<div v-if="optionChoice === 1 || optionChoice === 2" class="mt-3">
					<b-row>
						<b-col cols="10">
							<b-form-group>
								<b-input-group>
									<b-form-file
										v-model="$v.filesToUpload.$model"
										multiple
										:state="getStateValidationField('filesToUpload')"
										:file-name-formatter="formatNameFiles"
										style="margin-bottom: 0 !important"
										@input="handleInputFiles"
										:accept="supportedFileTypes"
									/>
									<b-input-group-append v-if="filesToUpload.length > 0">
										<b-button
											variant="outline-danger"
											class="pl-2 pt-0 pb-0"
											v-b-tooltip.hover
											:title="FormMSG(874, 'Clear files selected')"
											@click="handleClickClear"
										>
											<component :is="getLucideIcon('X')" :size="16" :stroke-width="2.75" />
										</b-button>
									</b-input-group-append>
								</b-input-group>
								<div class="invalid-feedback" v-if="$v.filesToUpload.$error && getStateValidationField('filesToUpload') !== null">
									{{ FormMSG(692, 'Please select file(s)') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col cols="10">
							<b-form-checkbox v-model="runAutomation" switch size="lg">
								<div class="fs-16 fw-700">{{ FormMSG(514, 'Run automation following import') }}</div>
							</b-form-checkbox>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
			<div class="mt-3">
				<b-collapse :visible="showReportImport">
					<div v-if="showReportImport">
						<fieldset class="scheduler-border border-groove-blue-streak pb-0">
							<legend class="scheduler-border text-color-blue-streak">
								{{ FormMSG(145, 'Report import') }}
							</legend>
							<div class="py-3">
								<b-row>
									<b-col>
										<b-table
											v-if="$screen.width >= 992"
											selected-variant="primary"
											hover
											selectable
											select-mode="single"
											responsive="sm"
											ref="documentPackageTable"
											sticky-header="700px"
											:items="reportFilesUploaded"
											style="text-align: left"
											:fields="reportImportFields"
											bordered
											small
											head-variant="dark"
											:empty-text="FormMSG(250, 'No data found')"
											show-empty
											tbody-tr-class="p-2"
										>
											<template #cell(status)="{ item }">
												<div class="wrap-status">
													<div
														class="status fw-700"
														:class="{
															validated: item.status === 0,
															'not-submitted': item.status > 0
														}"
														style="font-size: 0.7rem"
													>
														<div class="text-center">
															{{ item.status === 0 ? FormMSG(856, 'OK') : FormMSG(857, 'ERROR') }}
														</div>
													</div>
												</div>
											</template>
										</b-table>
									</b-col>
								</b-row>
							</div>
						</fieldset>
					</div>
				</b-collapse>
			</div>
		</form>
		<template #modal-footer="{ ok, cancel }">
			<div>
				<div v-if="importHasRunned === false" class="w-100 d-flex justify-content-end align-items-center">
					<b-button
						size="md"
						variant="custom-outline-gray"
						style="margin-top: 5px"
						class="w-138-px mr-3"
						:disabled="loadingSubmit"
						@click="cancel"
						block
					>
						{{ FormMSG(43, 'Cancel') }}
					</b-button>
					<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
						<div class="d-flex justify-content-center align-items-center">
							<b-spinner v-if="loadingSubmit" small />
							<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
						</div>
					</b-button>
				</div>
				<div v-else class="w-100 d-flex justify-content-end align-items-center">
					<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" :disabled="loadingSubmit" @click="cancel" block>
						{{ closeLabelAfterImport }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { importCarbonClapExternalSource } from '@/cruds/carbon.crud';
import { store } from '@/store';
import { IMPORT_CARBON_OCR_STATUS } from '@/shared/constants';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { isNotEmptyArray } from '@/utils/validators';
// import { minValue, required } from 'vuelidate/lib/validators';
import UploadFileBase64Mixin from '@/mixins/upload-file-base64.mixin';
import { b64toFile } from '@/components/Packages/Captures/capture.utils';
// import { getFileExtension } from '@/shared/helpers';
import { isFileSupported } from '@/utils/isFileSupported';
import { IMAGE_FILE_TYPE, PDF_FILE_TYPE } from '@/shared/constants';
// const axios = require('axios').create();

export default {
	name: 'NewEntryOptionDialog',
	props: {
		open: { type: Boolean, default: false, required: false }
	},
	mixins: [languageMessages, globalMixin, validationMixin, UploadFileBase64Mixin],
	data() {
		return {
			optionChoice: 0,
			loadingSubmit: false,
			runAutomation: false,
			filesToUpload: [],
			infoFilesUploaded: [],
			showReportImport: false,
			reportFilesUploaded: [],
			carbonsUploadedToReview: [],
			stateError: {
				show: false,
				msg: ''
			},
			importHasRunned: false,
			tmpFilesToUpload: [],
			uploadedFiles: []
		};
	},
	validations() {
		return {
			filesToUpload: { notEmpty: this.optionChoice === 1 || this.optionChoice === 2 ? isNotEmptyArray : {} }
		};
	},
	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		supportedFileTypes() {
			return IMAGE_FILE_TYPE + ',' + PDF_FILE_TYPE;
		},
		closeLabelAfterImport() {
			if (this.importHasRunned === true) {
				if (this.carbonsUploadedToReview.length > 0) {
					return this.FormMSG(458, 'Review imported entries');
				} else {
					return this.FormMSG(459, 'Close');
				}
			}

			return this.FormMSG(43, 'Cancel');
		},
		reportImportFields() {
			return [
				{
					key: 'fileName',
					label: this.FormMSG(841, 'File name'),
					formatter: (value, key, item) => {
						return this.getFileName(item);
					},
					sortable: true
				},
				{
					key: 'status',
					label: this.FormMSG(842, 'Status'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'infoError',
					label: this.FormMSG(906, 'Info error'),
					formatter: (value, key, item) => {
						return this.getInfoErrorImport(item);
					},
					sortable: true
				}
				// {
				// 	key: 'action',
				// 	label: '',
				// 	class: 'text-center',
				// 	sortable: false
				// }
			];
		}
	},
	methods: {
		getStateValidationField(field) {
			if (this.$v[field].$error === false) {
				return null;
			}

			return false;
		},
		handleClickClear() {
			this.filesToUpload = [];
			this.tmpFilesToUpload = [];
		},
		formatNameFiles(files) {
			let result = [];
			if (this.filesToUpload.length > 0) {
				for (let i = 0; i < this.filesToUpload.length; i++) {
					const element = this.filesToUpload[i];
					result.push(element.name);
				}
			}

			return result.join(', ');
		},
		async handleInputFiles(files) {
			files.forEach((file) => {
				if (isFileSupported(file, [this.supportedFileTypes])) {
					this.tmpFilesToUpload = _.concat(this.tmpFilesToUpload, file);
				} else {
					this.createToastForMobile(
						this.FormMSG(117, 'Error'),
						file.name + ' : ' + this.FormMSG(116, 'The file format is not supported. Please upload a supported file.'),
						'',
						'danger'
					);
				}
			});
			this.filesToUpload = this.tmpFilesToUpload;

			this.uploadedFiles = await this.onChange(this.filesToUpload);
		},
		getInfoErrorImport(reportFile) {
			if (reportFile.status === IMPORT_CARBON_OCR_STATUS.OCR_SCAN_ERROR) {
				return this.FormMSG(169, 'An error occurs during import');
			} else if (reportFile.status === IMPORT_CARBON_OCR_STATUS.OCR_IMPORT_ERROR) {
				return this.FormMSG(170, 'You has already use this file');
			}

			return '';
		},
		emitEventReviewResultImport(reportFile) {
			const carbons = reportFile.carbons.map((option) => ({
				...option,
				indexFromList: null
			}));
			this.$emit('new-entry-option:review-result-import', {
				carbons
			});
		},
		getFileName(reportFile) {
			let result = '';
			for (let i = 0; i < this.infoFilesUploaded.length; i++) {
				const item = this.infoFilesUploaded[i];
				if (item.xid === reportFile.message) {
					result = item.fileName;
					break;
				}
			}

			return result;
		},
		handleDismissedAlert() {
			this.stateError.show = false;
			this.stateError.msg = '';
		},
		async insertFiles() {
			let results = [];
			this.infoFilesUploaded = [];
			for (let i = 0; i < this.uploadedFiles.length; i++) {
				const element = this.uploadedFiles[i];

				const base64Data = element.file.split(';base64,');
				const fileName = element.fileName.substring(0, element.fileName.lastIndexOf('.'));
				const ext = element.fileName.substring(element.fileName.lastIndexOf('.') + 1);
				const file = b64toFile(base64Data[1], fileName, ext);

				let formData = new FormData();

				formData.append('uploadimage', file);
				formData.append('fileName', element.fileName);
				formData.append('fileType', element.fileType);
				formData.append('parentType', 'carbon_from_external_source');
				formData.append('parentId', 0);

				const urlStr = process.env.VUE_APP_GQL + '/upload';

				await this.$axios
					.$post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((xid) => {
						const xidSplited = xid.split('.')[0];
						results.push(xidSplited);

						this.infoFilesUploaded.push({
							xid: xidSplited,
							fileName: element.fileName,
							type: element.fileType
						});
					});
			}

			return results;
		},
		async handleSubmit() {
			if (this.optionChoice === 0) {
				this.$emit('new-entry-option:create');
				this.emitEventClose();
			} else {
				this.$v.$touch();

				if (!this.$v.$invalid) {
					const actionForLoader = async () => {
						try {
							this.loadingSubmit = true;
							const docXid = await this.insertFiles();

							this.reportFilesUploaded = await importCarbonClapExternalSource(docXid, this.optionChoice === 2, this.runAutomation);

							this.carbonsUploadedToReview = [];
							for (let i = 0; i < this.reportFilesUploaded.length; i++) {
								const element = this.reportFilesUploaded[i];
								if (element.status === 0) {
									this.carbonsUploadedToReview = _.concat(this.carbonsUploadedToReview, element.carbons);
								}
							}

							this.showReportImport = true;
							this.loadingSubmit = false;
							this.importHasRunned = true;
							this.$emit('new-entry-option:success');

							// this.emitEventClose();
						} catch (e) {
							console.error({ error: e });
							this.loadingSubmit = false;
							this.stateError.show = true;
							this.stateError.msg = this.resolveGqlError(e);
						}
					};

					await this.showLoader(actionForLoader, 'containerEntryOptionRef');
				}
			}
		},
		emitEventClose() {
			if (this.importHasRunned === true) {
				if (this.carbonsUploadedToReview.length > 0) {
					this.$emit('new-entry-option:review-result-import', {
						carbons: this.carbonsUploadedToReview
					});
				}
			}

			this.stateError.msg = '';
			this.stateError.show = false;
			this.filesToUpload = [];
			this.infoFilesUploaded = [];
			this.reportFilesUploaded = [];
			this.showReportImport = false;
			this.importHasRunned = false;
			this.carbonsUploadedToReview = [];
			this.tmpFilesToUpload = [];

			this.$emit('new-entry-option:close');
		}
	}
};
</script>
