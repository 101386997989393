<template>
	<div>
		<b-row>
			<b-col sm="12" md="9" lg="9" xl="9">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(65, 'Date')">
							<b-input-group>
								<b-form-input :value="dateOfTransport" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<component :is="getLucideIcon('Calendar')" :size="16" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(53, 'Crew member')">
							<b-form-input :value="crewOfTransport" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import moment from 'moment';

export default {
	name: 'GreenTransportForm',

	mixins: [languageMessages, globalMixin],

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			transport: {}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				this.transport = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		dateOfTransport() {
			if (!_.isNil(this.transport.createdAt)) {
				return moment(new Date(this.transport.createdAt.split('T')[0])).format('dddd, DD MMMM YYYY');
			}
			return '';
		},
		crewOfTransport() {
			if (!_.isNil(this.transport.user)) {
				return `${this.transport.user.name} ${this.transport.user.firstName}`;
			}
			return '';
		}
	}
};
</script>

<style scoped></style>
