<template>
	<div>
		<b-row>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(152, 'Type of premise')">
					<b-form-input :value="typeOfPremiseLabel" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(153, 'Country')">
					<b-form-input :value="countryLabel" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(30, 'Area premises')" label-for="area">
					<b-input-group>
						<b-form-input id="area" v-model="premise.filmingArea" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">m<sup>2</sup></span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group>
					<label for="occupation">
						<div style="display: flex; align-items: center">
							{{ FormMSG(11, 'Total no. of days occupation') }} &nbsp;
							<info-card>
								{{
									FormMSG(
										40,
										'Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable).'
									)
								}}
							</info-card>
						</div>
					</label>
					<b-input-group>
						<b-form-input id="occupation" v-model="premise.totNbOfDaysOccupation" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(49, 'days') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group>
					<label for="d">
						<div style="display: flex; align-items: center">
							{{ FormMSG(12, 'Total no. of man days') }} &nbsp;
							<info-card position="top-left">
								{{
									FormMSG(
										41,
										'Total number of days the property will be occupied times the average number of people expected to be in the space.'
									)
								}}
							</info-card>
						</div>
					</label>
					<b-input-group>
						<b-form-input id="days" type="number" v-model="premise.totNbOfManDays" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(49, 'days') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(17, 'No. of air conditionners')" label-for="airCond">
					<b-input-group>
						<b-form-input v-model="premise.nbOfAirConditioners" id="airCond" type="text" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(50, 'total') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import InfoCard from '@/views/Premises/infoCard.vue';
import { addressFormatter } from '@/shared/fomaters';

export default {
	name: 'GreenPremiseForm',
	components: { InfoCard },

	mixins: [languageMessages],

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			premise: {}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				console.log({ newVal });
				this.premise = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		typeOfPremiseLabel() {
			return this.GetTextFromMenuNumberAndMenuValue(1400, +this.premise.type);
		},
		countryLabel() {
			return addressFormatter(this.premise.address5);
		}
	},

	methods: {
		getLabel() {
			return this.GetTextFromMenuNumberAndMenuValue(1356, +this.location.type1);
		}
	}
};
</script>

<style scoped></style>
