var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "9", lg: "9", xl: "9" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(52, "Category") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: { value: _vm.categoryLabel, disabled: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(53, "Number of persons"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value: _vm.numberOfPersonsValue,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.accomodation.category === 0
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(54, "Dates") } },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: { value: _vm.datesValue, disabled: "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.accomodation.category === 0
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(55, "Accomodations name"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: { value: _vm.nameValue, disabled: "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.accomodation.category === 1
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(54, "Dates") } },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: { value: _vm.datesValue, disabled: "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.accomodation.category === 0
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "8", lg: "8", xl: "8" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(56, "Address") } },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.addressValue,
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.accomodation.category === 1
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(67, "Supplier") } },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.supplierValue,
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }