var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    {
      staticClass: "my-0 py-0 pb-0 mt-4",
      class: `${_vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"}`,
    },
    [
      _c(
        "legend",
        {
          staticClass: "text-color-rhapsody-in-blue-2 fw-400",
          class: `${
            _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
          }`,
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")]
      ),
      _c(
        "div",
        { staticClass: "pt-3 pb-4" },
        [
          _c("co-two-clap", {
            attrs: { "data-to-edit": _vm.dataToEdit },
            on: { "co-two-clap:change": _vm.handleCoTwoClapChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }