<template>
	<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
		<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
			{{ title }}
		</legend>
		<div class="pt-3 pb-4">
			<co-two-clap :data-to-edit="dataToEdit" @co-two-clap:change="handleCoTwoClapChange" />
		</div>
	</fieldset>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import CoTwoClap from './CoTwoClap';
import _ from 'lodash';

export default {
	name: 'GreenDetail',
	components: { CoTwoClap },
	props: {
		title: { type: String, default: '', required: false },
		dataToReview: { type: Object, default: () => ({}), required: false }
	},

	mixins: [languageMessages],

	data() {
		return {
			dataToEdit: {}
		};
	},

	watch: {
		dataToReview: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}') {
					this.dataToEdit = _.cloneDeep(newVal);
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>

<style scoped></style>
