<template>
	<b-modal
		id="reviewDialog"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		hide-backdrop
		:ok-only="!isUpdateAndExternal"
		:ok-title="okTitleLabel"
		:ok-variant="okVariant"
		:ok-disabled="loadingForMarkReview || loadingForSubmit"
		:cancel-disabled="loadingForSubmit"
		cancel-variant="outline-primary"
		@ok.prevent="handleOk"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@close="emitEventClose"
		@show="emitEventShow"
	>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					{{ titleCaption }}
				</b-col>
				<b-col v-if="isCreate === false && dataLength > 1" cols="4" class="pl-5">
					<div class="d-flex align-items-center justify-content-center">
						<div>
							<button
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="disableBtnBefore || loadingForMarkReview || loadingForSubmit"
								@click="handleClickBefore"
							>
								<component :is="getLucideIcon('ChevronLeft')" />
							</button>
						</div>
						<div>
							<div class="text-color-rhapsody-in-blue fs-16 fw-40">{{ FormMSG(45, 'Entry') }} {{ currentIndex }} / {{ dataLength }}</div>
						</div>
						<div>
							<button
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="disableBtnNext || loadingForMarkReview || loadingForSubmit"
								@click="handleClickAfter"
							>
								<component :is="getLucideIcon('ChevronRight')" />
							</button>
						</div>
					</div>
				</b-col>
			</b-row>
		</template>
		<div ref="content-review-dialog" id="content-review-dialog">
			<b-row v-if="stateError.show">
				<b-col>
					<b-alert variant="danger" show dismissible @dismissed="handleDismissedError">
						<div v-html="stateError.msg" />
					</b-alert>
				</b-col>
			</b-row>
			<b-row class="mb-2">
				<b-col cols="12">
					<div class="float-right">
						<div class="d-flex">
							<div class="wrap-status">
								<div
									class="status fw-700"
									:class="{
										validated: carbonData.verified === 3,
										'not-submitted': carbonData.verified === 0,
										pending: carbonData.verified === 2,
										info: carbonData.verified === 1
									}"
									style="font-size: 0.7rem"
								>
									<div class="text-center">
										{{ getLabelVerifiedStatus(carbonData.verified) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<perfect-scrollbar id="content-review-dialog-details">
				<b-row align-h="around" class="row-content-dialog-details">
					<b-col sm="12" md="4" lg="4" xl="4" class="carousel-wrapper">
						<b-row>
							<b-col style="height: 100%">
								<carousel
									v-if="imageUrls.length > 0"
									:value="imageUrls.map((img) => img.imgSrc).reverse()"
									:num-visible="numberItemVisible"
									:num-scroll="numberScroll"
									:key="carouselImgUpd"
									orientation="horizontal"
									:verticalViewPortHeight="`100%`"
									contentClass="main-carousel"
									style="height: 100%; justify-content: space-between"
								>
									<template #item="slotProps">
										<div class="product-item">
											<div class="product-item-content">
												<div class="parent cursor-pointer">
													<div class="slide" v-if="getFileExtension(slotProps.data) !== 'pdf'">
														<b-img-lazy
															:src="slotProps.data"
															:alt="slotProps.data"
															fluid-grow
															style="border-radius: 8px; height: 100%"
															class="px-1"
														/>
													</div>
													<div class="iframe-pdf" v-else>
														<iframe class="full full_h clearfix" :src="slotProps.data" frameborder="0" />
													</div>
												</div>
											</div>
										</div>
										<div :class="getFileExtension(slotProps.data) !== 'pdf' ? 'child' : 'w-100'">
											<div class="actions" :class="getFileExtension(slotProps.data) === 'pdf' ? 'd-flex justify-content-center' : ''">
												<b-button
													type="button"
													variant="transparent"
													class=""
													@click="showPicture(slotProps)"
													v-b-tooltip.hover.top="{ variant: 'primary' }"
													:title="FormMSG(61, 'Preview')"
												>
													<component
														:is="getLucideIcon('Eye')"
														:size="32"
														:stroke-width="2"
														style="margin-top: 4px"
														color="#3f9b9b"
													/>
												</b-button>
												<b-button
													type="button"
													variant="transparent"
													v-b-tooltip.hover.top="{ variant: 'danger' }"
													:title="FormMSG(60, 'Delete')"
													class=""
													@click="removeFile(slotProps)"
												>
													<component :is="getLucideIcon('Trash2')" :size="32" :stroke-width="2" color="#ea4e2c" />
												</b-button>
												<b-button
													variant="transparent"
													class="d-flex justify-content-center align-items-center"
													@click="onPickFile"
													v-b-tooltip.hover.top="{ variant: 'primary' }"
													:title="FormMSG(62, 'Add images/Files')"
													:style="`${imageUrls.length > 1 ? 'margin: 12px 0 8px 0' : ''}`"
												>
													<component :is="getLucideIcon('FilePlus')" :size="32" :stroke-width="2" color="#3f9b9b" />
													<!-- <div class="px-2" style="margin-bottom: -2px">
															{{ FormMSG(62, 'Add images/Files') }}
														</div> -->
												</b-button>
											</div>
										</div>
									</template>
								</carousel>
								<div v-else style="display: flex; height: 100%; width: 100%; align-items: center; justify-content: center">
									<b-button
										size="sm"
										variant="outline-primary"
										class="d-flex justify-content-center align-items-center"
										@click="onPickFile"
										:style="`${imageUrls.length > 1 ? 'margin: 12px 0 8px 0' : ''}`"
									>
										<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
										<div class="px-2" style="margin-bottom: -2px">
											{{ FormMSG(62, 'Add images/Files') }}
										</div>
									</b-button>
								</div>
							</b-col>
						</b-row>
					</b-col>
					<input type="file" style="display: none" ref="additional-detail-file" @change="onFilePicked" />
					<b-col sm="12" md="7" lg="7" xl="7" class="pb-5">
						<main-detail-for-review
							ref="mainDetailReview"
							:source="sourceType"
							:data-to-review="dataToEdit"
							:for-create="forCreate"
							:disable-form="disableForm"
							@main-detail-for-review:change="handleMainDetailForReviewChange"
						/>

						<additional-detail
							:title="titleAdditionalDetail"
							:source="sourceType"
							:for-create="disableForm"
							:data-to-review="dataToEdit"
							@additional-detail:change="handleAdditionalDetailChange"
							@additional-detail:image-picked="handleAdditionalDetailImagePicked"
						/>

						<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
								{{ titleGreenDetail }}
							</legend>
							<div class="pt-3 pb-4">
								<co-two-clap
									ref="coTwoClapRef"
									:parent-id="entityId"
									:entity-type="sourceType"
									:for-create="!isEditMode"
									:is-submit="submitCarbon"
									:external-data="dataForCarbon"
									:external-carbon-data="carbonData"
									:disable-transport-forms="disableTransportForms"
									:disable-accomodation-forms="disableAccomodationForms"
									:disable-waste-forms="disableWasteForms"
									:disable-carbon-type-selector="disableCarbonSelector"
									:use-address-transport-string="useAddressTransportString"
									:hide-distance-known-transport="hideDistanceKnownForExpenseMileage"
									:hide-location-selector="hideLocationSelector"
									:hide-premise-menu="false"
									@co-two-clap:reviewed="handleClickReviewed"
									@co-two-clap:init-carbon="handleCoTwoClapInitCarbon"
									@co-two-clap:change="handleCoTwoClapChange"
								/>
							</div>
						</fieldset>

						<b-row v-if="forCreate === false && dataToEdit.verified === false" class="mt-3">
							<b-col>
								<b-button variant="primary" class="pl-4 pr-4" :disabled="loadingForMarkReview || loadingForSubmit" @click="handleClickReviewed">
									<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />
									<b-spinner v-if="loadingForMarkReview === true" small />
									{{ FormMSG(48, 'Mark as reviewed') }}
								</b-button>
							</b-col>
						</b-row>
					</b-col>
					<capture-images
						ref="capture-images"
						multiple
						preview-mode="button"
						:pictures="imageUrls"
						:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
						:dispatch-event-add-action="true"
						@change="handleImgsTaken"
					/>
				</b-row>
			</perfect-scrollbar>

			<carbon-template-dialog
				:open="showCarbonTemplateDialog"
				:data-to-edit="carbonTemplateData"
				:raw-text-data="rawTextAutomation"
				@carbon-template-dialog:close="handleCarbonTemplateDialogClose"
				@carbon-template:submit-success="handleCarbonTemplateSubmitSuccess"
			/>
		</div>
		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="md"
					variant="custom-outline-gray"
					class="w-138-px mr-3"
					:disabled="loadingForSubmit || loadingForRunAutomate || loadingForMarkReview"
					@click="cancel"
					block
				>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-dropdown
					id="review-dialog-more-action"
					variant="outline-primary"
					class="w-138-px mr-3"
					:disabled="loadingForRunAutomate || loadingForSubmit || loadingForMarkReview || loadingResetStatus"
				>
					<template #button-content>
						<b-spinner v-if="loadingForRunAutomate || loadingForMarkReview || loadingResetStatus" small></b-spinner> {{ FormMSG(189, 'More') }}
					</template>
					<b-dropdown-item @click="handleClickRunAutomation">
						<div class="d-flex">
							<div class="mr-2">
								<component :is="getLucideIcon('Play')" :size="16" />
							</div>
							<div>
								{{ FormMSG(197, 'Run automations') }}
							</div>
						</div>
					</b-dropdown-item>
					<b-dropdown-item v-if="!forCreate" @click="handleClickCreateCarbonTemplate">
						<div class="d-flex">
							<div class="mr-2">
								<component :is="getLucideIcon('Settings')" :size="16" />
							</div>
							<div>
								{{ FormMSG(198, 'Create new automation') }}
							</div>
						</div>
					</b-dropdown-item>
					<b-dropdown-item v-if="showContextStatus && showResetStatusMenu" @click="handleClickResetStatus">
						<div class="d-flex">
							<div class="mr-2">
								<component :is="getLucideIcon('RotateCcw')" :size="16" />
							</div>
							<div>
								{{ FormMSG(289, 'Reset status') }}
							</div>
						</div>
					</b-dropdown-item>
					<b-dropdown-item v-if="showContextStatus && carbonData.templateId > 0" @click="handleClickViewAutomation(carbonData.templateId)">
						<div class="d-flex">
							<div class="mr-2">
								<component :is="getLucideIcon('Regex')" :size="16" />
							</div>
							<div>
								{{ FormMSG(291, 'View automation') }}
							</div>
						</div>
					</b-dropdown-item>
				</b-dropdown>

				<b-button
					size="md"
					:variant="okVariant"
					class="w-138-px mr-3"
					:disabled="loadingForSubmit || loadingForRunAutomate || loadingForMarkReview"
					@click="ok"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingForSubmit" small />
						<div :class="`${loadingForSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ okTitleLabel }}</div>
					</div>
				</b-button>
				<b-button
					v-if="!forCreate"
					size="md"
					variant="blue-sapphire"
					class="w-300-px"
					:disabled="loadingForSubmit || loadingForRunAutomate || loadingForMarkReview || disableMarkReview"
					@click="handleClickReviewed"
				>
					<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />
					<b-spinner v-if="loadingForMarkReview === true" small />
					{{ labelMarkReview }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import MainDetailForReview from './MainDetailForReview';
import AdditionalDetail from './AdditionalDetail';
import GreenDetail from './GreenDetail';
import CoTwoClap from './CoTwoClap';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import CaptureImages from '@/components/Packages/Captures/components/Main';

import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getExpenseItem } from '@/cruds/expense.crud';
import { provideFileDataToUpload } from '@/components/Packages/Captures/capture.utils';
import {
	runAutomation,
	addUpdCarbonFromExternalSource,
	getCarbonFromExternalSource,
	updCarbon,
	getCarbonTemplate,
	changeCarbonVerifiedStatus
} from '@/cruds/carbon.crud';
import { store } from '@/store';
import Carousel from 'primevue/carousel';
import { getFileExtension } from '@/shared/helpers';
import CarbonTemplateDialog from '@/modules/carbonclap/components/carbon-template/CarbonTemplateDialog.vue';
import { getLocation } from '@/cruds/locations.crud';
import { getTransport } from '@/cruds/transports.crud';
import { getAccomodation } from '@/cruds/accomodation.crud';
import _ from 'lodash';
import { getWasteDetail } from '@/cruds/waste.crud';
import { CARBON_VERIFIED_STATUS_MENU, ERROR_STRING } from '@/shared/constants';
import AdobePdfReaderViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/AdobePdfReaderViewer.vue';
import ImageShowRoomManager from '@/components/Packages/FIlesPreviewer/imageShowroom.manager';
import Loading from 'vue-loading-overlay';
import { INIT_DATA_CARBON, EXPENSE_TYPE } from '@/shared/constants';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { getBudgetDetail } from '@/cruds/categorie.crud';

const SOURCE_TYPES = ['', 'location', 'accomodation', 'expense'];
const INIT_DATA_TO_EDIT = {
	id: 0,
	description: '',
	carbonDateTime: new Date(),
	documentXid: [],
	createdAt: new Date().toISOString(),
	user: { name: '', firstName: '' },
	amount: 0,
	amountVat: 0,
	amountTotal: 0,
	supplierId: null,
	category: null,
	supplier: { name: '' },
	costCenter: '',
	reportType: 0,
	carbon: {
		carbonValues: {
			kgCoTwo: 0
		}
	}
};

const INIT_CARBON_TEMPLATE_DATA = {
	id: 0,
	supplierId: 0,
	keyWordOne: '',
	keyWordTwo: '',
	keyWordThree: '',
	searchInDescOnly: true,
	coTwoCategory: 1,
	type: 0,
	subType: 0,
	additionalSubType: 0,
	vehiculeId: 0,
	reason: 0,
	km: 0,
	liter: 0,
	mcube: 0,
	kwh: 0,
	msquare: 0,
	number: 0,
	boolValue: false,
	mealRed: 0,
	mealWhite: 0,
	mealVege: 0,
	mealNotSpecified: 0,
	unitAmountTotal: 0,
	costCenters: '',
	locationId: 0,

	averageWasteCardboard: 0,
	averageWastePaper: 0,
	averageWastePlastic: 0,
	averageWasteFood: 0,
	averageWasteGeneral: 0,
	averageWastePaint: 0,
	averageWasteWood: 0,
	averageWasteSteel: 0,
	averageWasteBatterie: 0,

	averageElec: 0,
	averageGas: 0,
	averageWater: 0,
	averageGeneratorUnlead: 0,
	averageGeneratorGas: 0,
	averageGeneratorHydrogene: 0,
	averageTransportation: 0,

	averageMealVege: 0,
	averageMealRed: 0,
	averageMealWhite: 0,
	averageMealNotSpecified: 0
};

const axios = require('axios').create();

export default {
	name: 'ReviewDialog',

	mixins: [languageMessages, globalMixin],

	components: {
		Loading,
		CarbonTemplateDialog,
		MainDetailForReview,
		AdditionalDetail,
		GreenDetail,
		CoTwoClap,
		CapturesPackage,
		CaptureImages,
		Carousel,
		AdobePdfReaderViewer,
		PerfectScrollbar
	},

	props: {
		open: { type: Boolean, default: false },
		forCreate: { type: Boolean, default: true, required: false },
		source: { type: Number, default: 1, required: false },
		dataToReview: { type: Object, default: () => ({}), required: false },
		dataLength: { type: Number, default: 0 },
		currentIndex: { type: Number, default: 0 }
	},

	data() {
		const manager = new ImageShowRoomManager(this, {});
		return {
			manager,
			isCreate: false,
			sourceType: 1,

			// for the new carousel
			numberItemVisible: 1,
			numberScroll: 1,
			carouselImgUpd: 0,

			dataToEdit: _.cloneDeep(INIT_DATA_TO_EDIT),
			entityId: 0,

			loadingForMarkReview: false,
			loadingForSubmit: false,
			loadingForRunAutomate: false,
			loadingResetStatus: false,

			dataLocationToUpload: null,
			submitCarbon: false,

			dataForCarbon: {
				description: ''
			},

			fileToInsertExternal: null,
			filesToInsert: [],

			imageUrl: '',
			imageUrls: [],

			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			carbonDataId: 0,
			carbonTemplateData: INIT_CARBON_TEMPLATE_DATA,
			showCarbonTemplateDialog: false,

			rawTextAutomation: {},
			haveRunAutomateExecuted: false,

			stateError: {
				show: false,
				msg: ''
			},

			imgNavigateTo: 0
		};
	},

	watch: {
		dataToReview: {
			async handler(newVal) {
				const actionForLoader = async () => {
					if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
						await this.initEntryDataAndCarbon(newVal, true);
					}
				};

				await this.showLoader(actionForLoader, 'content-review-dialog');
			},
			deep: true,
			immediate: true
		},
		source: {
			handler(newVal) {
				this.sourceType = newVal;
			},
			deep: true,
			immediate: true
		},
		forCreate: {
			handler(newVal) {
				this.isCreate = newVal;
			},
			deep: true,
			immediate: true
		}
	},

	computed: {
		hideLocationSelector() {
			return this.carbonData.entityType === 5 || this.carbonData.entityType === 10;
		},
		showActionBtnImage() {
			return this.imageUrls.length > 0;
		},
		disablePreviousImage() {
			return this.imgNavigateTo === 0;
		},
		disableNextImage() {
			return this.imgNavigateTo === this.imageUrls.length - 1;
		},
		labelMarkReview() {
			if (this.currentIndex < this.dataLength && this.carbonData.verified === 3) {
				return this.FormMSG(268, 'Go to next');
			}
			if (this.currentIndex === this.dataLength && this.carbonData.verified === 3) {
				return this.FormMSG(251, 'Mark as reviewed');
			}
			if (this.currentIndex === this.dataLength) {
				return this.FormMSG(251, 'Mark as reviewed');
			}

			return this.FormMSG(781, 'Mark as reviewed and go to next');
		},
		disableMarkReview() {
			if (this.currentIndex === this.dataLength && this.carbonData.verified === 3) {
				return true;
			}

			return false;
		},
		showContextStatus() {
			if (this.showResetStatusMenu === false && this.carbonData.templateId === 0) {
				return false;
			}

			return true;
		},

		showResetStatusMenu() {
			if ((this.carbonData.entityType === 1 || this.carbonData.entityType === 8) && this.carbonData.verified > 0) {
				return true;
			} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(this.carbonData.entityType) && this.carbonData.verified !== 2) {
				return true;
			}

			return false;
		},
		useAddressTransportString() {
			if (this.source === 6 && this.forCreate === false) {
				return true;
			} else if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		hideDistanceKnownForExpenseMileage() {
			if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		disableCarbonSelector() {
			if (
				(this.source === 2 ||
					this.source === 5 ||
					this.source === 10 ||
					this.source === 6 ||
					this.source === 9 ||
					(this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL)) &&
				this.forCreate === false
			) {
				return true;
			}

			return false;
		},
		disableTransportForms() {
			if (this.source === 2 && this.forCreate === false) {
				return true;
			} else if (this.source === 6 && this.dataToEdit.category === 1 && this.forCreate === false) {
				return true;
			} else if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		disableWasteForms() {
			return this.source === 9 && this.forCreate === false;
		},
		disableAccomodationForms() {
			return this.source === 6 && this.forCreate === false;
		},
		disableForm() {
			return this.source !== 8;
		},
		isUpdateAndExternal() {
			return this.forCreate === false;
		},
		isEditMode() {
			return JSON.stringify(this.dataToReview) !== '{}' && !_.isNil(this.dataToReview);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(newVal) {
				return newVal;
			}
		},
		okVariant() {
			if (this.isCreate === true) {
				return 'primary';
			} else {
				if (this.isUpdateAndExternal) {
					return 'primary';
				} else {
					return 'custom-primary-gray';
				}
			}
		},
		okTitleLabel() {
			if (this.isCreate === true) {
				return this.FormMSG(149, 'Add entry');
			} else {
				if (this.isUpdateAndExternal) {
					return this.FormMSG(581, 'Update');
				} else {
					return this.FormMSG(150, 'Close');
				}
			}
		},
		disableBtnBefore() {
			return this.currentIndex === 1;
		},
		disableBtnNext() {
			return this.currentIndex === this.dataLength;
		},
		titleCaption() {
			if (this.isCreate === true) {
				return this.FormMSG(20, 'New Green Entry');
			} else if (this.isCreate === false) {
				return this.FormMSG(21, 'Green entry review');
			}
		},

		titleAdditionalDetail() {
			if (this.isCreate === true) {
				return this.FormMSG(26, 'Additional detail');
			} else {
				switch (this.sourceType) {
					case 5:
						return this.FormMSG(40, 'Location detail');
					case 6:
						return this.FormMSG(41, 'Accomodation detail');
					case 1:
						return this.FormMSG(42, 'Expense detail');
					default:
						return this.FormMSG(49, 'Detail');
				}
			}
		},

		titleGreenDetail() {
			return this.FormMSG(30, 'Green detail');
		}
	},

	methods: {
		handleClickPreviousImage() {
			this.imgNavigateTo--;
		},
		handleClickNextImage() {
			this.imgNavigateTo++;
		},
		async handleClickRunAutomation() {
			const actionForLoader = async () => {
				try {
					this.loadingForRunAutomate = true;
					// this.haveRunAutomateExecuted = true;

					await runAutomation(null, true, [+this.dataToReview.id]);

					await this.initEntryDataAndCarbon(this.dataToReview, false);

					this.emitEventRunAutomation();

					await this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(841, 'Automation executed successfully!'));

					this.loadingForRunAutomate = false;
				} catch (e) {
					this.loadingForRunAutomate = false;
					this.stateError.show = true;
					this.stateError.msg = this.resolveGqlError(e);
				}
			};

			await this.showLoader(actionForLoader, 'content-review-dialog');
		},
		emitEventRunAutomation() {
			this.$emit('review-dialog:automation-end', {
				carbonData: this.carbonData,
				currentIndex: this.currentIndex - 1
			});
		},
		async initEntryDataAndCarbon(newVal, useOmit = true) {
			try {
				this.sourceType = newVal.entityType;
				this.entityId = this.dataToReview.entityId;
				this.imgNavigateTo = 0;
				this.carouselImgUpd++;

				let fieldOmittedCarbonData =
					useOmit === true ? ['__typename', 'carbonValues', 'department', 'function', 'supplier', 'user', 'updatedAt', 'createdAt'] : [];

				if (newVal.entityType === 8) {
					const result = await getCarbonFromExternalSource(parseInt(this.dataToReview.entityId, 10));

					this.dataToEdit = {
						...result,
						reportType: result.carbon.reportType,
						costCenter: result.carbon.costCenter
					};

					if (this.dataToEdit.carbon.rawText !== '') {
						this.rawTextAutomation = { text: this.dataToEdit.carbon.rawText };
					}

					this.dataToEdit.costCenter = result.carbon.costCenter;

					this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);

					this.prepareImageUrls(this.dataToEdit.documentXid);
				} else if (newVal.entityType === 1) {
					const result = await getExpenseItem(parseInt(this.dataToReview.entityId, 10));
					this.dataToEdit = {
						...result,
						description: result.carbon.description,
						createdAt: result.date,
						supplierName: result.supplierName,
						supplierId: result.supplierId,
						user: result.user,
						amount: result.amountTotal,
						supplier: result.supplier,
						documentXid: result.images,
						costCenter: result.carbon.costCenter,
						reportType: result.carbon.reportType
					};

					if (this.dataToEdit.carbon.rawText !== '') {
						this.rawTextAutomation = { text: this.dataToEdit.carbon.rawText };
					}

					this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);

					this.prepareImageUrls(result.images);
				} else if (newVal.entityType === 5 || newVal.entityType === 10) {
					const result = await getLocation(this.dataToReview.entityId);
					const carbonDataFinded = result.carbon.find((option) => +option.id === +this.dataToReview.id);
					this.dataToEdit = {
						...result,
						description: result.setName,
						createdAt: result.createdAt,
						supplierName: '',
						supplierId: 0,
						user: null,
						amount: 0,
						supplier: null,
						documentXid: result.images,
						type1: result.type1,
						type: result.type,
						address: result.address5,
						filmingArea: result.filmingArea,
						filmingDays: result.locationDates.length,
						costCenter: carbonDataFinded.costCenter,
						consumptionDate: carbonDataFinded.strDate,
						carbon: carbonDataFinded,
						reportType: result.carbon.reportType
					};
					this.carbonData = _.omit(carbonDataFinded, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);

					this.prepareImageUrls(result.images);
				} else if (newVal.entityType === 2) {
					const result = await getTransport(this.dataToReview.entityId);
					this.dataToEdit = {
						...result,
						description: result.extraInfo,
						createdAt: result.departureDate,
						supplierName: '',
						supplierId: 0,
						user: result.user,
						amount: 0,
						supplier: null,
						costCenter: result.carbon.costCenter,
						reportType: result.carbon.reportType
					};

					this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);

					this.prepareImageUrls(result.documentXid);
				} else if (newVal.entityType === 6) {
					const result = await getAccomodation(this.dataToReview.entityId);
					this.dataToEdit = {
						...result[0],
						description: result[0].description,
						createdAt: new Date(),
						supplierName: '',
						supplierId: 0,
						user: null,
						amount: 0,
						documentXid: [],
						costCenter: result[0].carbon.costCenter,
						reportType: result[0].carbon.reportType
					};

					this.carbonData = _.omit(result[0].carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(parseInt(result[0].carbon.id));
				} else if (newVal.entityType === 9) {
					const result = await getWasteDetail(this.dataToReview.entityId);
					this.dataToEdit = {
						...result,
						description: result.carbon.description,
						createdAt: result.createdAt,
						supplierName: '',
						supplierId: 0,
						user: null,
						amount: 0,
						supplier: null,
						documentXid: [],
						costCenter: result.carbon.costCenter,
						reportType: result.carbon.reportType
					};

					this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);
				} else if (newVal.entityType === 7) {
					const result = await getBudgetDetail(this.dataToReview.entityId);
					this.dataToEdit = {
						...result,
						description: result.description,
						createdAt: result.createdAt,
						supplierName: '',
						supplierId: 0,
						user: null,
						amount: 0,
						supplier: null,
						documentXid: [],
						costCenter: result.costCenter,
						reportType: result.carbon.reportType
					};

					this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
					this.carbonDataId = _.cloneDeep(+result.carbon.id);
				}
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},
		onFrameLoaded(index) {
			this.imageUrls[index].loading = false;
		},
		buildImagesList(images, focusIndex) {
			const imagesList = [];
			this.manager.dispatch('setImagesList', []);
			const e = process.env.VUE_APP_PATH_IMG_URL;
			for (let i = 0; i < images.length; i++) {
				const file = images[i];

				const el = _.isObject(file) ? file : { xid: file, src: e + file, ext: 'png' };

				imagesList.push(el);
			}
			this.manager.dispatch('setImagesList', imagesList);
			const __i = _.isNil(focusIndex) ? 0 : focusIndex;
			if (!_.isNil(imagesList[__i])) {
				// this.manager.dispatch('getCommentStats', imagesList[__i].xid);
				this.setActiveCarouselImage(imagesList[__i]);
			}
		},
		setActiveCarouselImage(imgObj) {
			this.manager.states.currentActiveSlideExt = imgObj.ext;
			this.manager.dispatch('setCurrentActiveSlide', imgObj.xid);
		},
		async handleClickResetStatus() {
			const carbonDataOriginal = _.cloneDeep(this.carbonData);

			try {
				this.loadingResetStatus = true;
				let reviewStatusToSend = 0;

				if (this.carbonData.entityType === 1) {
					if (this.dataToEdit.type === EXPENSE_TYPE.TRAVEL) {
						reviewStatusToSend = 0;
						this.carbonData.verified = 0;
					} else {
						reviewStatusToSend = 0;
						this.carbonData.verified = 0;
						this.carbonData.coTwoCategory = 0;
					}
				} else if (this.carbonData.entityType === 8 || this.carbonData.entityType === 7) {
					reviewStatusToSend = 0;
					this.carbonData.verified = 0;
					this.carbonData.coTwoCategory = 0;
				} else if ([2, 3, 4, 5, 6, 9, 10].includes(this.carbonData.entityType)) {
					reviewStatusToSend = 2;
					this.carbonData.verified = 2;
				}

				const resultVerifiedStatus = await changeCarbonVerifiedStatus([+this.carbonDataId], reviewStatusToSend);

				if (resultVerifiedStatus.length === 0) {
					// SEND EVENT RESET STATUS
					this.$emit('review-dialog:reset-status', { carbonData: this.carbonData });

					this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(259, 'Updated status successfully!'));
				} else {
					this.createToastForMobile(this.FormMSG(1254, 'Error'), this.FormMSG(1259, 'Error encountered!!'));
				}

				this.loadingResetStatus = false;
			} catch (e) {
				this.loadingResetStatus = false;
				this.stateError.show = true;
				this.stateError.msg = this.resolveGqlError(e);
				this.carbonData = carbonDataOriginal;
				this.$emit('review-dialog:reset-status', { carbonData: carbonDataOriginal });
			}
		},
		handleDismissedError() {
			this.stateError.msg = '';
			this.stateError.show = false;
		},
		async handleClickViewAutomation(templateId) {
			try {
				this.carbonTemplateData = await getCarbonTemplate(+templateId);

				this.showCarbonTemplateDialog = true;
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},
		getLabelVerifiedStatus(value) {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);
			return menues.find((option) => option.value === value).text;
		},
		openNewTab(index) {
			const url = `${process.env.VUE_APP_GQL}/images/${this.imageUrls[index].xid}`;
			if (`${this.imageUrls[index].xid}`.length === 0) {
				const base64ImageData = this.imageUrls[index].imgSrc;
				const nameOfImage = this.imageUrls[index].file.name;
				const _window = window.open(url, '_blank');
				_window.document.write(`
					<html>
					<head>
						<title>${nameOfImage}</title>
						<style>
						html, body {
							margin: 0;
							padding: 0;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						img {
							max-width: 100%;
							max-height: 100%;
						}
						</style>
					</head>
					<body>
						<img src="${base64ImageData}" />
					</body>
					</html>
				`);
				_window.document.title = nameOfImage;
			} else {
				window.open(url, '_blank');
			}
		},
		getImage(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		getImageName(path) {
			const pathSplited = path.split('.')[0];
			return process.env.VUE_APP_GQL + '/imagethumb/' + path;
		},
		handleCarbonTemplateSubmitSuccess() {
			this.carbonTemplateData = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);
			this.showCarbonTemplateDialog = false;
		},
		handleCarbonTemplateDialogClose() {
			this.carbonTemplateData = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);
			this.showCarbonTemplateDialog = false;
		},
		handleCoTwoClapChange({ carbonData }) {
			if (carbonData.isFreighter) {
				carbonData.kg = carbonData.number;
			}
			let oldReportType = this.dataToEdit.carbon.reportType;
			let newStatus = carbonData.verified;
			if (this.carbonData.verified === 1) {
				newStatus = this.carbonData.verified;
			}
			this.carbonData = { ...carbonData, verified: newStatus, reportType: oldReportType };
		},
		async handleClickCreateCarbonTemplate() {
			this.carbonTemplateData = this.mappingCarbonDataToCarbonTemplate();

			this.showCarbonTemplateDialog = true;
		},
		mappingCarbonDataToCarbonTemplate() {
			let carbonTemplate = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);

			carbonTemplate.keyWordOne = this.dataToEdit.description;
			carbonTemplate.searchInDescOnly = true;
			carbonTemplate.supplierId = this.dataToEdit.supplierId;
			carbonTemplate.userId = store.userID();
			carbonTemplate.coTwoCategory = +this.carbonData.coTwoCategory;
			carbonTemplate.boolValue = +this.carbonData.boolValue;
			carbonTemplate.type = +this.carbonData.type;
			carbonTemplate.locationId = +this.carbonData.locationId;
			carbonTemplate.costCenters = this.dataToEdit.category > 0 ? this.dataToEdit.costCenter : '';

			if (this.carbonData.coTwoCategory === 3) {
				carbonTemplate.subType = +this.carbonData.subType;
			} else if ([1, 5, 15].includes(this.carbonData.coTwoCategory)) {
				carbonTemplate.subType = 1;
			} else if (this.carbonData.coTwoCategory === 11) {
				carbonTemplate.subType = this.carbonData.subType;
			} else if (this.carbonData.coTwoCategory === 4) {
				if (this.carbonData.transport) {
					carbonTemplate.subType = this.carbonData.transport.subType;
					carbonTemplate.type = this.carbonData.transport.type;
					carbonTemplate.additionalSubType = this.carbonData.transport.additionalSubType;
					carbonTemplate.km = this.carbonData.transport.km;
					carbonTemplate.reason = this.carbonData.transport.reason;
					carbonTemplate.vehiculeId = this.carbonData.transport.vehiculeId;
					carbonTemplate.number = this.carbonData.transport.nbVehicle;
				}
			}
			if (this.carbonData.coTwoCategory === 5) {
				carbonTemplate.subType = 1;
				carbonTemplate.number = 1;
				if (this.dataToEdit.amount > 0) {
					if (this.carbonData.type === 1) {
						carbonTemplate.averageWasteCardboard = this.dataToEdit.amount / +this.carbonData.kgCardboardWaste;
						carbonTemplate.averageWastePaper = this.dataToEdit.amount / +this.carbonData.kgPaperWaste;
						carbonTemplate.averageWastePlastic = this.dataToEdit.amount / +this.carbonData.kgPlasticWaste;
						carbonTemplate.averageWasteFood = this.dataToEdit.amount / +this.carbonData.kgFoodWaste;
						carbonTemplate.averageWasteGeneral = this.dataToEdit.amount / +this.carbonData.kgGeneralWaste;
						carbonTemplate.averageWasteBatterie = this.dataToEdit.amount / +this.carbonData.unitBatterieWaste;
					} else if (this.carbonData.type === 2) {
						carbonTemplate.averageWastePaint = this.dataToEdit.amount / +this.carbonData.kgPaintWaste;
						carbonTemplate.averageWasteCardboard = this.dataToEdit.amount / +this.carbonData.kgCardboardWaste;
						carbonTemplate.averageWasteWood = this.dataToEdit.amount / +this.carbonData.kgWoodWaste;
						carbonTemplate.averageWasteSteel = this.dataToEdit.amount / +this.carbonData.kgSteelWaste;
						carbonTemplate.averageWastePlastic = this.dataToEdit.amount / +this.carbonData.kgPlasticWaste;
					}
				} else {
					carbonTemplate.averageWasteCardboard = 0;
					carbonTemplate.averageWastePaper = 0;
					carbonTemplate.averageWastePlastic = 0;
					carbonTemplate.averageWasteFood = 0;
					carbonTemplate.averageWasteGeneral = 0;
					carbonTemplate.averageWastePaint = 0;
					carbonTemplate.averageWasteWood = 0;
					carbonTemplate.averageWasteSteel = 0;
					carbonTemplate.averageWasteBatterie = 0;
				}
			} else if (this.carbonData.coTwoCategory === 2) {
				carbonTemplate.number = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / (this.carbonData.numberOfNight * this.carbonData.numberBedroom);
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 1) {
				if (this.dataToEdit.amount > 0) {
					const calcKwH = this.dataToEdit.amount / (this.carbonData.elecMeterEnd - this.carbonData.elecMeterStart);
					const calcGas = this.dataToEdit.amount / (this.carbonData.gasMeterEnd - this.carbonData.gasMeterStart);
					const calcWater = this.dataToEdit.amount / (this.carbonData.waterMeterEnd - this.carbonData.waterMeterStart);

					carbonTemplate.kwh = 1;

					carbonTemplate.averageElec = !_.isFinite(calcKwH) ? 0 : calcKwH;
					carbonTemplate.averageGas = !_.isFinite(calcGas) ? 0 : calcGas;
					carbonTemplate.averageWater = !_.isFinite(calcWater) ? 0 : calcWater;

					if (this.carbonData.generatorFuelType === 1) {
						const calcGenGas = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorGas = !_.isFinite(calcGenGas) ? 0 : calcGenGas;
					} else if (this.carbonData.generatorFuelType === 2) {
						const calcGenUnlead = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorUnlead = !_.isFinite(calcGenUnlead) ? 0 : calcGenUnlead;
					} else if (this.carbonData.generatorFuelType === 3) {
						const calcGenHyd = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorHydrogene = !_.isFinite(calcGenHyd) ? 0 : calcGenHyd;
					}
				} else {
					carbonTemplate.averageElec = 0;
					carbonTemplate.averageGas = 0;
					carbonTemplate.averageWater = 0;
					carbonTemplate.averageGeneratorGas = 0;
					carbonTemplate.averageGeneratorUnlead = 0;
					carbonTemplate.averageGeneratorHydrogene = 0;
				}
			} else if (this.carbonData.coTwoCategory === 3) {
				if (this.dataToEdit.amount > 0) {
					const calcKwH = this.dataToEdit.amount / this.carbonData.kwh;
					const calcGas = this.dataToEdit.amount / this.carbonData.mcube;
					const calcWater = this.dataToEdit.amount / this.carbonData.waterVolumeUsed;
					const calcGenGas = this.dataToEdit.amount / this.carbonData.mcube;
					const calcGenUnlead = this.dataToEdit.amount / this.carbonData.liter;
					const calcGenHyd = this.dataToEdit.amount / this.carbonData.mcube;

					carbonTemplate.kwh = 1;

					carbonTemplate.averageElec = !_.isFinite(calcKwH) ? 0 : calcKwH;
					carbonTemplate.averageGas = !_.isFinite(calcGas) ? 0 : calcGas;
					carbonTemplate.averageWater = !_.isFinite(calcWater) ? 0 : calcWater;
					carbonTemplate.averageGeneratorGas = !_.isFinite(calcGenGas) ? 0 : calcGenGas;
					carbonTemplate.averageGeneratorUnlead = !_.isFinite(calcGenUnlead) ? 0 : calcGenUnlead;
					carbonTemplate.averageGeneratorHydrogene = !_.isFinite(calcGenHyd) ? 0 : calcGenHyd;
				} else {
					carbonTemplate.averageElec = 0;
					carbonTemplate.averageGas = 0;
					carbonTemplate.averageWater = 0;
					carbonTemplate.averageGeneratorGas = 0;
					carbonTemplate.averageGeneratorUnlead = 0;
					carbonTemplate.averageGeneratorHydrogene = 0;
				}
			} else if (this.carbonData.coTwoCategory === 4) {
				carbonTemplate.type = this.carbonData.type;
				carbonTemplate.subType = this.carbonData.subType;
				carbonTemplate.additionalSubType = this.carbonData.additionalSubType;
				carbonTemplate.number = this.carbonData.numberOfVehicule;
				carbonTemplate.km = this.carbonData.km;
				carbonTemplate.vehiculeId = this.carbonData.vehiculeId;
				carbonTemplate.reason = this.carbonData.reason;

				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
					if (this.carbonData.boolValue === true) {
						carbonTemplate.km = this.carbonData.km;
						carbonTemplate.unitAmountTotal = 0;
					} else {
						carbonTemplate.km = 1;
					}
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 6) {
				carbonTemplate.subType = 1;
				carbonTemplate.mealVege = 1;
				carbonTemplate.mealRed = 0;
				carbonTemplate.mealWhite = 0;
				carbonTemplate.mealNotSpecified = 0;

				if (this.dataToEdit.amount > 0) {
					const calcMealVege = this.dataToEdit.amount / this.carbonData.mealVege;
					const calcMealRed = this.dataToEdit.amount / this.carbonData.mealRed;
					const calcMealWhite = this.dataToEdit.amount / this.carbonData.mealWhite;
					const calcMealNotSpecified = this.dataToEdit.amount / this.carbonData.mealNotSpecified;
					carbonTemplate.averageMeal = !_.isFinite(calcMealVege) ? 0 : calcMealVege;

					carbonTemplate.averageMealVege = !_.isFinite(calcMealVege) ? 0 : calcMealVege;
					carbonTemplate.averageMealRed = !_.isFinite(calcMealRed) ? 0 : calcMealRed;
					carbonTemplate.averageMealWhite = !_.isFinite(calcMealWhite) ? 0 : calcMealWhite;
					carbonTemplate.averageMealNotSpecified = !_.isFinite(calcMealNotSpecified) ? 0 : calcMealNotSpecified;
				} else {
					carbonTemplate.averageMealVege = 0;
					carbonTemplate.averageMealRed = 0;
					carbonTemplate.averageMealWhite = 0;
					carbonTemplate.averageMealNotSpecified = 0;
				}
			} else if (this.carbonData.coTwoCategory === 10) {
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;

					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 12) {
				carbonTemplate.km = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 13) {
				carbonTemplate.liter = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.liter;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			}

			return carbonTemplate;
		},
		handleCoTwoClapInitCarbon(payload) {
			this.dataToEdit.carbonValues = payload.carbonValues;
		},
		prepareImageUrls(images) {
			if (images.length > 0) {
				this.imageUrl = `${process.env.VUE_APP_GQL}/images/${images[0]}`;
				images.map((option) => {
					const extension = getFileExtension(option);
					this.imageUrls.push({
						hoverAction: false,
						imgSrc: `${process.env.VUE_APP_GQL}/images/${option}`,
						src: `${process.env.VUE_APP_GQL}/images/${option}`,
						fromDb: true,
						xid: option,
						file: null,
						extension,
						loading: extension === 'pdf'
					});
				});

				this.buildImagesList(this.imageUrls);
			}
		},
		getFileExtension(fileName) {
			return getFileExtension(fileName);
		},
		removeFile(data) {
			const action = async () => {
				try {
					const reversedImgsList = [...this.imageUrls].reverse();
					if (reversedImgsList[data.index].fromDb) {
						const response = await this.$axios.$get(`/removeimage/${reversedImgsList[data.index].xid}`);
						if (ERROR_STRING.ITEM_NOT_FOUND === response)
							this.createToastForMobile(this.FormMSG(1254, 'Error'), this.FormMSG(63, 'File not found'), 'danger');
					}
					reversedImgsList.splice(data.index, 1);
					this.imageUrls = [...reversedImgsList].reverse();
					this.carouselImgUpd++;
					this.$refs['additional-detail-file'].value = null;
					this.prepareFilesToInsert();
				} catch (error) {
					this.createToastForMobile(this.FormMSG(1254, 'Error'), this.FormMSG(70, 'Something went wrong'), 'danger');
				}
			};

			this.confirmModal(action, this.FormMSG(814, 'Are you sure ?'), 'danger');
		},
		getFileExtensionB64(valueB64) {
			let mimeType = valueB64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

			return mimeType;
		},
		getExtensionFileByXid(docXid) {
			return getFileExtension(docXid);
		},
		showPicture(data) {
			const images = this.imageUrls.map((img) => {
				if (img.fromDb === true) {
					return {
						xid: img.xid,
						src: `${process.env.VUE_APP_GQL}/images/${img.xid}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img.xid,
						ext: getFileExtension(img.xid)
					};
				} else {
					return {
						xid: img.imgSrc,
						src: img.imgSrc,
						thumb: img.imgSrc,
						ext: this.getFileExtensionB64(img.imgSrc)
					};
				}
			});

			this.$previewImages({
				images,
				focusIndex: data.index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});

			// this.emitEventClose();
		},
		handleMouseLeave(image) {
			image.hoverAction = false;
		},
		handleMouseEnter(image) {
			image.hoverAction = true;
		},
		prepareFilesToInsert() {
			let result = [];
			this.imageUrls.map((option) => {
				if (option.fromDb === false) {
					result.push(option.file);
				}
			});
			this.filesToInsert = result;
		},
		/**
		 * @param {Array} images
		 */
		async handleImgsTaken(images) {
			const newList = images.map((image) => {
				const matching = this.imageUrls.find((imgObj) => imgObj.xid === image);
				if (matching) {
					return matching;
				} else {
					const extension = getFileExtension(image);
					return {
						hoverAction: false,
						imgSrc: `${process.env.VUE_APP_GQL}/images/${image}`,
						src: `${process.env.VUE_APP_GQL}/images/${image}`,
						fromDb: true,
						xid: image,
						file: image,
						extension,
						loading: extension === 'pdf'
					};
				}
			});
			this.imageUrls = newList;
			this.carouselImgUpd++;
			this.prepareFilesToInsert();
		},

		onFilePicked(e) {
			const files = e.target.files;
			let filename = files[0].name;
			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
				if (!this.searchStringInArray(this.imageUrl, this.imageUrls)) {
					this.imageUrls.push({
						hoverAction: false,
						imgSrc: fileReader.result,
						fromDb: false,
						xid: '',
						file: files[0],
						extension: files[0].name.split('.')[1]
					});

					this.prepareFilesToInsert();
				}
			});
			fileReader.readAsDataURL(files[0]);
			this.image = files[0];
		},
		searchStringInArray(str, jsonArray) {
			return _.some(jsonArray, (obj) => {
				return _.some(obj, (value) => {
					return _.isString(value) && value.includes(str);
				});
			});
		},
		onPickFile() {
			this.$refs['additional-detail-file'].click();
		},
		onFileAdded() {
			this.$refs['capture-images'].$refs['add-images-none-pwa'].click();
		},
		handleAdditionalDetailImagePicked(images) {
			this.filesToInsert = images;
		},
		handleCoTwoClapEndSubmit(payload) {
			this.$emit('review-dialog:submit', {
				data: {
					...payload
				}
			});
			this.loadingForSubmit = false;
			this.submitCarbon = false;
			this.emitEventClose();
		},
		handleAdditionalDetailChange(payload) {
			if (this.sourceType === 8) {
				this.dataToEdit.carbonDateTime = new Date(payload.carbonDateTime);
				this.dataToEdit.amount = payload.amount;
				this.dataToEdit.amountVat = payload.amountVat;
				this.dataToEdit.amountTotal = +payload.amount + +payload.amountVat;
				this.dataToEdit.supplierId = +payload.supplierId;
				this.dataToEdit.category = payload.category;
				this.dataToEdit.costCenter = payload.costCenter;
			}
		},
		handleMainDetailForReviewChange({ description, coTwo, reportType }) {
			this.dataToEdit.description = description;
			this.dataToEdit.coTwo = coTwo;
			this.dataToEdit.reportType = reportType;
			this.dataToEdit.carbon.reportType = reportType;
			this.dataForCarbon.description = description;
		},
		emitEventShow() {
			this.$emit('review-dialog:show');
		},
		async handleOk(closed = true) {
			try {
				const coTwoClapInvalid = this.$refs['coTwoClapRef'].isInvalid();
				const mainDetailInvalid = this.$refs['mainDetailReview'].isInvalid();
				if (coTwoClapInvalid === true || mainDetailInvalid === true) return;

				if (this.isCreate === false) {
					this.loadingForSubmit = true;
					let resCarbonUpdated = {};
					if (this.dataToReview.entityType === 8) {
						let externalInput = {
							carbonDateTime: this.dataToEdit.carbonDateTime,
							description: this.dataToEdit.description,
							reportType: this.dataToEdit.reportType,
							amount: parseFloat(this.dataToEdit.amount),
							amountVat: parseFloat(this.dataToEdit.amountVat),
							amountTotal: parseFloat(this.dataToEdit.amountTotal),
							supplierId: this.dataToEdit.supplierId,
							category: this.dataToEdit.category,
							carbon: {
								...this.carbonData,
								carbonId: +this.dataToEdit.carbon.id,
								reportType: this.dataToEdit.reportType
							}
						};
						if (this.carbonData.isFreighter) {
							this.carbonData.km = this.carbonData.number;
							this.carbonData.number = 0;
						}

						const res = await addUpdCarbonFromExternalSource(+this.dataToReview.entityId, externalInput);
						this.dataToEdit.carbon = res.carbon;
						resCarbonUpdated = res.carbon;
					} else {
						this.carbonData.entityType = this.dataToReview.entityType;
						this.carbonData.entityId = this.dataToReview.entityId;
						this.carbonData.amount = parseFloat(this.dataToReview.amount);
						this.carbonData.amountVat = parseFloat(this.dataToReview.amountVat);
						this.carbonData.amountTotal = parseFloat(this.dataToReview.amountTotal);
						this.carbonData.description = this.sourceType === 1 ? this.dataToEdit.description : this.dataToReview.description;
						this.carbonData.supplierId = this.dataToReview.supplierId;
						this.carbonData.reportType = this.dataToEdit.reportType;
						this.carbonData.carbonDateTime = this.dataToReview.carbonDateTime;
						this.carbonData.carbonId = +this.dataToReview.id;
						resCarbonUpdated = await updCarbon(+this.dataToReview.id, { ...this.carbonData });
						this.dataToEdit.carbon = resCarbonUpdated;
						if (this.carbonData.isFreighter) {
							this.carbonData.km = this.carbonData.number;
							this.carbonData.number = 0;
						}
					}
					if (this.filesToInsert.length > 0) {
						await this.insertFiles(+this.dataToReview.entityId);
					}

					this.loadingForSubmit = false;

					this.createToastForMobile(this.FormMSG(415, 'Success'), this.FormMSG(812, 'Update successfully!'));
					this.emitEventUpdateSuccess({
						...resCarbonUpdated,
						carbonId: +resCarbonUpdated.id
					});

					// if (closed) {
					// 	this.emitEventClose();
					// }
				} else {
					this.loadingForSubmit = true;
					if (this.carbonData.isFreighter) {
						this.carbonData.km = this.carbonData.number;
						this.carbonData.number = 0;
					}

					let externalInput = {
						carbonDateTime: this.dataToEdit.carbonDateTime,
						description: this.dataToEdit.description,
						// forForecastReportOnly: this.dataToEdit.forForecastReportOnly,
						amount: parseFloat(this.dataToEdit.amount),
						amountVat: parseFloat(this.dataToEdit.amountVat),
						amountTotal: parseFloat(this.dataToEdit.amountTotal),
						supplierId: this.dataToEdit.supplierId,
						category: this.dataToEdit.category,
						carbon: {
							...this.carbonData,
							reportType: this.dataToEdit.reportType
						}
					};
					let result = await addUpdCarbonFromExternalSource(0, externalInput);

					this.entityId = +result.carbon.entityId;

					if (this.filesToInsert.length > 0) {
						await this.insertFiles(+result.carbon.entityId);
					}

					this.createToastForMobile(this.FormMSG(415, 'Success'), this.FormMSG(416, 'External data added successfully!'));
					this.emitEventSubmit({
						...result.carbon,
						carbonId: +this.dataToEdit.carbon.id
					});
					this.emitEventClose();
				}
			} catch (e) {
				this.loadingForSubmit = false;
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},
		emitEventSubmit(carbonData) {
			this.$emit('review-dialog:submit', {
				carbonData
			});
		},
		emitEventUpdateSuccess(carbonData) {
			this.$emit('review-dialog:update-success', {
				carbonData
			});
		},
		async insertFiles(externalId) {
			for (let i = 0; i < this.filesToInsert.length; i++) {
				const element = this.filesToInsert[i];

				let formData = new FormData();
				const parentType = this.sourceType === 2 ? 'transport' : 'carbon_from_external_source';

				try {
					const fileData = await provideFileDataToUpload(element);
					console.log(fileData);
					formData.append('uploadimage', fileData.file);
					formData.append('fileName', fileData.name);
					formData.append('fileType', fileData.type);
					formData.append('parentType', parentType);
					formData.append('parentId', externalId);

					// let urlStr = process.env.VUE_APP_GQL + '/upload';
					await this.$axios.$post('upload', formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					});
				} catch (e) {
					console.error({ e });
				}
			}
		},
		async handleClickReviewed() {
			this.loadingForMarkReview = true;
			this.carbonData.verified = 3;
			await this.handleOk(false);

			this.initData();
			this.loadingForMarkReview = false;
			this.$emit('review-dialog:reviewed', {
				currentIndex: this.currentIndex - 1
			});
		},
		handleClickBefore() {
			this.initData();
			this.$emit('review-dialog:before', {
				currentIndex: this.currentIndex - 1
			});
		},
		handleClickAfter() {
			this.initData();
			this.$emit('review-dialog:after', {
				currentIndex: this.currentIndex - 1
			});
		},
		emitEventClose(refreshList = false) {
			const carbonData = _.cloneDeep(this.carbonData);
			this.initData();

			this.$emit('review-dialog:close', { refreshList, carbonData, currentIndex: this.currentIndex });
		},
		initData() {
			this.dataToEdit = _.cloneDeep(INIT_DATA_TO_EDIT);
			this.carbonData = _.cloneDeep(INIT_DATA_CARBON);
			this.isCreate = false;
			this.sourceType = 1;
			this.entityId = 0;
			this.loadingForMarkReview = false;
			this.loadingForSubmit = false;
			this.submitCarbon = false;
			this.dataForCarbon = { description: '' };
			this.dataLocationToUpload = null;
			this.fileToInsertExternal = null;
			this.filesToInsert = [];
			this.imageUrl = '';
			this.imageUrls = [];
			this.rawTextAutomation = {};
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style>
.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
	background-color: transparent;
}

.row-content-dialog-details {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 15px;
	top: 0px;
}
.obj-pos-abs {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
</style>
