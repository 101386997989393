var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "adobe-pdf-reader-file--component-wrapper" },
    [
      _c("div", { staticClass: "full full_h clearfix" }, [
        !_vm.isShowSignatureViewerWarning
          ? _c("div", { staticStyle: { height: "100%" } }, [
              !_vm.adobeApiPDFReady
                ? _c(
                    "div",
                    { staticClass: "loading-container" },
                    [_c("b-spinner", { attrs: { variant: "primary" } })],
                    1
                  )
                : _vm._e(),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.adobeApiPDFReady,
                    expression: "adobeApiPDFReady",
                  },
                ],
                ref: "adobePdfReader",
                attrs: { id: _vm.containerId },
              }),
            ])
          : _c(
              "div",
              { staticStyle: { height: "100%" } },
              [
                _c(
                  "b-overlay",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { show: _vm.loading, opacity: "0.85" },
                    scopedSlots: _vm._u([
                      {
                        key: "overlay",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center bg-transparent" },
                              [
                                _c(
                                  "p",
                                  [
                                    _c("loading", {
                                      attrs: {
                                        active: _vm.loading,
                                        loader: "bars",
                                        color: "#00A09C",
                                        width: 64,
                                      },
                                      on: {
                                        "update:active": function ($event) {
                                          _vm.loading = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "loading-text" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(100, "Please wait...")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("iframe", {
                      staticClass: "full full_h clearfix",
                      attrs: { src: _vm.src, frameborder: "0" },
                      on: { load: _vm.onFrameLoaded },
                    }),
                  ]
                ),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }