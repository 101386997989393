var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(75, "New entry options"),
        "header-class": "header-class-modal-doc-package",
        size: _vm.showReportImport ? "xl" : "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c("div", [
                _vm.importHasRunned === false
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-100 d-flex justify-content-end align-items-center",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px mr-3",
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "md",
                              variant: "custom-outline-gray",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: cancel },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(43, "Cancel")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px",
                            attrs: {
                              size: "md",
                              variant: "primary",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: ok },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center",
                              },
                              [
                                _vm.loadingSubmit
                                  ? _c("b-spinner", { attrs: { small: "" } })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                                    staticStyle: { "margin-top": "1px" },
                                  },
                                  [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "w-100 d-flex justify-content-end align-items-center",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "md",
                              variant: "custom-outline-gray",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: cancel },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.closeLabelAfterImport) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "form",
        { ref: "containerEntryOptionRef" },
        [
          _vm.stateError.show
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "danger",
                            show: "",
                            dismissible: "",
                          },
                          on: { dismissed: _vm.handleDismissedAlert },
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.stateError.msg) },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-radio",
            {
              attrs: { value: 0 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "fs-14 fw-700" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(48, "Without receipt")) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 1 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(_vm._s(_vm.FormMSG(158, "Main receipt")) + " -"),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(73, "Create one entry per receipt/invoice")
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 2 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(_vm._s(_vm.FormMSG(101, "Detailed import")) + " -"),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          83,
                          "Create one entry per line of each receipt/invoice"
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.optionChoice === 1 || _vm.optionChoice === 2,
              },
            },
            [
              _vm.optionChoice === 1 || _vm.optionChoice === 2
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-file", {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                        },
                                        attrs: {
                                          multiple: "",
                                          state:
                                            _vm.getStateValidationField(
                                              "filesToUpload"
                                            ),
                                          "file-name-formatter":
                                            _vm.formatNameFiles,
                                          accept: _vm.supportedFileTypes,
                                        },
                                        on: { input: _vm.handleInputFiles },
                                        model: {
                                          value: _vm.$v.filesToUpload.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.filesToUpload,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.filesToUpload.$model",
                                        },
                                      }),
                                      _vm.filesToUpload.length > 0
                                        ? _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover",
                                                      modifiers: {
                                                        hover: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "pl-2 pt-0 pb-0",
                                                  attrs: {
                                                    variant: "outline-danger",
                                                    title: _vm.FormMSG(
                                                      874,
                                                      "Clear files selected"
                                                    ),
                                                  },
                                                  on: {
                                                    click: _vm.handleClickClear,
                                                  },
                                                },
                                                [
                                                  _c(_vm.getLucideIcon("X"), {
                                                    tag: "component",
                                                    attrs: {
                                                      size: 16,
                                                      "stroke-width": 2.75,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.$v.filesToUpload.$error &&
                                  _vm.getStateValidationField(
                                    "filesToUpload"
                                  ) !== null
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  692,
                                                  "Please select file(s)"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { switch: "", size: "lg" },
                                  model: {
                                    value: _vm.runAutomation,
                                    callback: function ($$v) {
                                      _vm.runAutomation = $$v
                                    },
                                    expression: "runAutomation",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "fs-16 fw-700" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          514,
                                          "Run automation following import"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("b-collapse", { attrs: { visible: _vm.showReportImport } }, [
                _vm.showReportImport
                  ? _c("div", [
                      _c(
                        "fieldset",
                        {
                          staticClass:
                            "scheduler-border border-groove-blue-streak pb-0",
                        },
                        [
                          _c(
                            "legend",
                            {
                              staticClass:
                                "scheduler-border text-color-blue-streak",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(145, "Report import")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _vm.$screen.width >= 992
                                        ? _c("b-table", {
                                            ref: "documentPackageTable",
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: {
                                              "selected-variant": "primary",
                                              hover: "",
                                              selectable: "",
                                              "select-mode": "single",
                                              responsive: "sm",
                                              "sticky-header": "700px",
                                              items: _vm.reportFilesUploaded,
                                              fields: _vm.reportImportFields,
                                              bordered: "",
                                              small: "",
                                              "head-variant": "dark",
                                              "empty-text": _vm.FormMSG(
                                                250,
                                                "No data found"
                                              ),
                                              "show-empty": "",
                                              "tbody-tr-class": "p-2",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(status)",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-status",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "status fw-700",
                                                              class: {
                                                                validated:
                                                                  item.status ===
                                                                  0,
                                                                "not-submitted":
                                                                  item.status >
                                                                  0,
                                                              },
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.7rem",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        item.status ===
                                                                          0
                                                                          ? _vm.FormMSG(
                                                                              856,
                                                                              "OK"
                                                                            )
                                                                          : _vm.FormMSG(
                                                                              857,
                                                                              "ERROR"
                                                                            )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3551335753
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }