var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "viewLogAutomationDialog",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(45, "Log for change status"),
        size: "xl",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-only": "",
        "ok-variant": "primary",
        "ok-title": _vm.FormMSG(569, "Close"),
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.emitEventClose.apply(null, arguments)
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _vm.$screen.width >= 992
            ? _c("b-table", {
                ref: "documentPackageTable",
                staticStyle: { "text-align": "left" },
                attrs: {
                  "selected-variant": "primary",
                  hover: "",
                  selectable: "",
                  "select-mode": "single",
                  responsive: "sm",
                  "sticky-header": "500px",
                  items: _vm.dataList,
                  fields: _vm.greenTableFields,
                  bordered: "",
                  striped: "",
                  small: "",
                  "head-variant": "dark",
                  "empty-text": _vm.FormMSG(250, "No data found"),
                  "show-empty": "",
                  "tbody-tr-class": "p-2",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(status)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "wrap-status" }, [
                            _c(
                              "div",
                              {
                                staticClass: "status fw-700 not-submitted",
                                staticStyle: { "font-size": "0.7rem" },
                              },
                              [
                                _c("div", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(857, "ERROR")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3383366748
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }