<template>
	<div>
		<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
			<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
				{{ FormMSG(15, 'Main detail') }}
			</legend>
			<div class="pt-3 pb-4">
				<b-row>
					<b-col sm="12" md="3" lg="3" xl="3">
						<b-form-group :label="sourceLabel">
							<b-form-input :value="sourceCaption" class="bg-color-grams-hair" disabled />
						</b-form-group>
						<div>
							<b-form-group :label="FormMSG(12365, 'Report')">
								<b-form-select @change="handleChangeReportOption" v-model="dataToSend.reportType" :options="reportTypeOptions"> </b-form-select>
							</b-form-group>
						</div>
					</b-col>
					<b-col sm="12" md="6" lg="6" xl="6">
						<b-form-group :label="descriptionLabel">
							<b-form-textarea
								v-model="$v.dataToSend.description.$model"
								:class="{ 'bg-color-grams-hair': disableDescription }"
								autofocus
								rows="4"
								:disabled="disableDescription"
								:state="!$v.dataToSend.description.$error === false ? false : null"
								@change="handleDescriptionChange"
							/>
							<div class="invalid-feedback" v-if="$v.dataToSend.description.$error">
								{{ FormMSG(458, 'Description is required') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="3" lg="3" xl="3">
						<b-form-group :label="coTwoLabel">
							<b-input-group>
								<b-form-input v-model="dataToSend.coTwo" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(283, 'kg') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendKgCo2 } from '@/shared/helpers';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

const SOURCE_TYPES = ['', 'location', 'accomodation', 'expense'];

export default {
	name: 'MainDetailForReview',

	props: {
		source: { type: Number, default: 1, required: false },
		dataToReview: { type: Object, default: () => ({}), required: false },
		disableForm: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, validationMixin],
	components: {
		Treeselect
	},
	data() {
		return {
			sourceType: 8,
			dataToSend: {
				entityType: '',
				description: null,
				coTwo: 0,
				reportType: 0
			}
		};
	},

	validations() {
		let result = {
			dataToSend: {
				description: { required }
			}
		};

		// if (this.source !== 8 || this.source !== 1) {
		// 	result = {
		// 		dataToSend: {
		// 			description: {}
		// 		}
		// 	};
		// }

		return result;
	},

	watch: {
		dataToReview: {
			handler(newVal) {
				this.dataToSend.description = newVal.description;
				this.dataToSend.coTwo = newVal.carbon ? rendKgCo2(newVal.carbon.carbonValues.kgCoTwo) : 0;
				this.dataToSend.reportType = newVal.carbon && newVal.carbon.reportType ? newVal.carbon.reportType : 0;
			},
			immediate: true,
			deep: true
		},
		source: {
			handler(newVal) {
				this.sourceType = newVal;
			},
			deep: true,
			immediate: true
		},
		'dataToSend.reportType': {
			handler(newVal) {
				/*if (newVal === undefined) {
					if (this.sourceType === 1) {
						this.dataToSend.reportType = 0;
					}
					if (this.sourceType === 2) {
						this.dataToSend.reportType = 0;
					}
					if (this.sourceType === 5) {
						this.dataToSend.reportType = 2;
					}
					if (this.sourceType === 6) {
						this.dataToSend.reportType = 0;
					}
					if (this.sourceType === 7) {
						this.dataToSend.reportType = 1;
					}
					if (this.sourceType === 8) {
						this.dataToSend.reportType = 0;
					}
					if (this.sourceType === 9) {
						this.dataToSend.reportType = 0;
					}
					if (this.sourceType === 10) {
						this.dataToSend.reportType = 0;
					}
					this.dataToSend.reportType = 0; // Set a default value when it is undefined
				}*/
			},
			immediate: true
		}
	},

	computed: {
		disableDescription() {
			if (this.source === 1 && this.disableForm === true) {
				return false;
			}

			return this.disableForm;
		},
		reportTypeOptions() {
			return this.FormMenu(11045);
		},
		sourceLabel() {
			return this.FormMSG(20, 'Source');
		},
		descriptionLabel() {
			switch (this.sourceType) {
				case 5:
					return this.FormMSG(25, 'Name / Description');
				default:
					return this.FormMSG(21, 'Description');
			}
		},
		coTwoLabel() {
			return this.FormMSG(22, 'CO2');
		},
		sourceCaption() {
			return this.GetTextFromMenuNumberAndMenuValue(11006, this.source);
		}
	},

	methods: {
		isInvalid() {
			if (this.source === 8 || this.source === 1) {
				this.$v.$touch();

				return this.$v.$invalid;
			}

			return false;
		},
		handleDescriptionChange() {
			this.emitChange();
		},
		handleChangeReportOption(payload) {
			this.emitChange();
		},
		emitChange() {
			this.$emit('main-detail-for-review:change', this.dataToSend);
		},
		getSourceValue(value) {
			return this.GetTextFromMenuNumberAndMenuValue(11006, value);
		}
	}
};
</script>

<style scoped></style>
