var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12", lg: "12", xl: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(65, "Date") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.dateOfExpense,
                                  disabled: "",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c(_vm.getLucideIcon("Calendar"), {
                                        tag: "component",
                                        attrs: { size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(53, "Crew member") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: { value: _vm.crewOfExpense, disabled: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(156, "Cost center") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: { value: _vm.categoryLabel, disabled: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(48, "Amount") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.expense.amount
                                    ? _vm.expense.amount
                                    : 0,
                                  disabled: "",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(67, "Supplier") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value: _vm.expense.supplierName,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }