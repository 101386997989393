var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(87, "Date") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.dateOfWaste, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(88, "Department") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: {
                      value: _vm.dataToSend.departmentName,
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(89, "Location / Premise") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.dataToSend.locationName, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }