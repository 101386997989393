var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "fieldset",
      {
        staticClass: "my-0 py-0 pb-0 mt-4",
        class: `${
          _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
        }`,
      },
      [
        _c(
          "legend",
          {
            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
            class: `${
              _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
            }`,
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
        ),
        _c("div", { staticClass: "pt-3 pb-4" }, [
          _vm.sourceType === 5
            ? _c(
                "div",
                [
                  _c("green-location-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 6
            ? _c(
                "div",
                [
                  _c("green-accomodation-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 2
            ? _c(
                "div",
                [
                  _c("green-transport-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 1
            ? _c(
                "div",
                [
                  _c("green-expense-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 9
            ? _c(
                "div",
                [
                  _c("green-waste-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 10
            ? _c(
                "div",
                [
                  _c("green-premise-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sourceType === 7
            ? _c(
                "div",
                [
                  _c("green-budget-details-form", {
                    attrs: { "data-to-edit": _vm.dataToEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          [3, 4, 8].includes(_vm.sourceType)
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      !_vm.disableForm
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(810, "Date") } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      value: _vm.dataToSend.carbonDateTime,
                                      locale: _vm.lang,
                                      masks: _vm.masks,
                                    },
                                    on: {
                                      input: _vm.handleInputCarbonDateTime,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            inputValue,
                                            togglePopover,
                                          }) {
                                            return [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass: "bg-white",
                                                    attrs: {
                                                      value: inputValue,
                                                      readonly: "",
                                                    },
                                                    on: {
                                                      click: togglePopover,
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                          on: {
                                                            click:
                                                              togglePopover,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Calendar"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#06263E",
                                                                size: 16,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      665940656
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.disableForm === true
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(65, "Date") } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        staticClass: "bg-color-grams-hair",
                                        attrs: {
                                          value: _vm.dateOfExternal,
                                          disabled: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c(
                                                _vm.getLucideIcon("Calendar"),
                                                {
                                                  tag: "component",
                                                  attrs: { size: 16 },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(941, "Categories") },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.mapCategories,
                                  "disable-branch-nodes": true,
                                },
                                on: {
                                  input: _vm.handleChangeCategories,
                                  select: _vm.handleSelectCategorie,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "value-label",
                                      fn: function ({ node }) {
                                        return _c(
                                          "div",
                                          { attrs: { title: node.label } },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(node.label)),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [_vm._v(_vm._s(node.label))]
                                          ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2968668878
                                ),
                                model: {
                                  value: _vm.dataToSend.category,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "category", $$v)
                                  },
                                  expression: "dataToSend.category",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _vm.disableForm === false
                            ? _c("supplier-selector", {
                                staticClass: "mb-3",
                                attrs: {
                                  title: `${
                                    !_vm.isSupplierUpdate
                                      ? _vm.FormMSG(250, "Add supplier details")
                                      : _vm.FormMSG(
                                          251,
                                          "Update supplier details"
                                        )
                                  }`,
                                  label:
                                    _vm.$screen.width > 576
                                      ? _vm.FormMSG(481, "Supplier")
                                      : "",
                                  placeholder: _vm.FormMSG(
                                    13,
                                    "Filter by name"
                                  ),
                                  addlabel: _vm.FormMSG(5442, "New supplier"),
                                  "update-label": _vm.FormMSG(40, "Update"),
                                  "show-map": false,
                                  type: null,
                                  "supplier-id": _vm.dataToSend.supplierId,
                                  "use-new-design": "",
                                  version: "1.0",
                                },
                                on: { change: _vm.handleSupplierChange },
                              })
                            : _vm._e(),
                          _vm.disableForm === true
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(481, "Supplier"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "bg-color-grams-hair":
                                        _vm.forCreate === false,
                                    },
                                    attrs: {
                                      value: _vm.dataToReview.supplier.name,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(454, "Amount excl. tax"),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "bg-color-grams-hair": _vm.disableForm,
                                    },
                                    attrs: { disabled: _vm.disableForm },
                                    on: {
                                      input: _vm.handleChangeAmount,
                                      change: _vm.emitChange,
                                    },
                                    model: {
                                      value: _vm.$v.dataToSend.amount.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.amount,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.dataToSend.amount.$model",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(455, "VAT amount") },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "bg-color-grams-hair": _vm.disableForm,
                                    },
                                    attrs: { disabled: _vm.disableForm },
                                    on: {
                                      input: _vm.handleChangeAmountVat,
                                      change: _vm.emitChange,
                                    },
                                    model: {
                                      value: _vm.$v.dataToSend.amountVat.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.amountVat,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.amountVat.$model",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  456,
                                  "Total amount incl. tax"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { disabled: _vm.disableForm },
                                    on: {
                                      input: _vm.handleChangeAmountTotal,
                                      change: _vm.emitChange,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataToSend.amountTotal.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.amountTotal,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.amountTotal.$model",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("input", {
                    ref: "additional-detail-file",
                    staticStyle: { display: "none" },
                    attrs: { type: "file" },
                    on: { change: _vm.onFilePicked },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }