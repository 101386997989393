import _ from 'lodash';

export default {
	name: 'UploadFileBase64Mixin',
	data() {
		return {
			progress: 0,
			uploadedFiles: []
		};
	},
	methods: {
		async onChange($event) {
			this.$bus.$emit('upload-file:start', this.progress);

			const files = $event;

			const promises = Object.entries(files).map((item) => {
				return new Promise((resolve, reject) => {
					const [index, file] = item;

					const reader = new FileReader();
					reader.readAsBinaryString(file);

					reader.onload = (event) => {
						this.$bus.$emit('upload-file:inprogress', this.progress++);
						let bytes = Array.from(new Uint8Array(event.target.result));

						resolve({
							index,
							bytes: btoa(bytes.map((item) => String.fromCharCode(item)).join('')),
							fileName: file.name,
							fileType: file.type,
							file: `data:${file.type};base64,${btoa(event.target.result)}`
						});
					};

					reader.onerror = (error) => {
						this.$bus.$emit('upload-file:error', error);
						reject(error);
					};
				});
			});

			await Promise.all(promises).then((results) => {
				this.uploadedFiles = results;
				this.$bus.$emit('upload-file:inprogress', this.progress++);
				this.$bus.$emit('upload-file:end');
				this.progress = 0;
			});

			return await new Promise((resolve) => {
				resolve(this.uploadedFiles);
			});
		}
	}
};
