<template>
	<div>
		<b-row>
			<b-col sm="12" md="12" lg="12" xl="12">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(65, 'Date')">
							<b-input-group>
								<b-form-input :value="dateOfExpense" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<component :is="getLucideIcon('Calendar')" :size="16" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(53, 'Crew member')">
							<b-form-input :value="crewOfExpense" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(156, 'Cost center')">
							<b-form-input :value="categoryLabel" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(48, 'Amount')">
							<b-input-group>
								<b-form-input :value="expense.amount ? expense.amount : 0" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(67, 'Supplier')">
							<b-form-input :value="expense.supplierName" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import CurrencySvg from '@/components/Svg/Currency';
import moment from 'moment';
import { getFileExtension } from '@/shared/helpers';
import { Carousel, Slide } from 'vue-carousel';

export default {
	name: 'GreenExpenseForm',

	mixins: [languageMessages, globalMixin],

	components: { CurrencySvg, Carousel, Slide },

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			expense: {},
			imageUrls: []
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				this.expense = _.cloneDeep(newVal);
				this.imageUrls = [];
				if (newVal.documentXid.length > 0) {
					newVal.documentXid.map((option) => {
						this.imageUrls.push({
							hoverAction: false,
							imgSrc: `${process.env.VUE_APP_GQL}/images/${option}`
						});
					});
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		categoryLabel() {
			return this.expense.costCenter;
		},
		dateOfExpense() {
			if (!_.isNil(this.expense.createdAt)) {
				return moment(new Date(this.expense.createdAt.split('T')[0])).format('dddd, DD MMMM YYYY');
			}
			return '';
		},
		crewOfExpense() {
			if (!_.isNil(this.expense.user)) {
				return `${this.expense.user.name} ${this.expense.user.firstName}`;
			}
			return '';
		}
	}
};
</script>

<style scoped></style>
