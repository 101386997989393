<template>
	<div>
		<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
			<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
				{{ title }}
			</legend>
			<div class="pt-3 pb-4">
				<div v-if="sourceType === 5">
					<green-location-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 6">
					<green-accomodation-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 2">
					<green-transport-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 1">
					<green-expense-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 9">
					<green-waste-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 10">
					<green-premise-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="sourceType === 7">
					<green-budget-details-form :data-to-edit="dataToEdit" />
				</div>
				<div v-if="[3, 4, 8].includes(sourceType)">
					<b-row>
						<b-col v-if="!disableForm" sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(810, 'Date')">
								<v-date-picker :value="dataToSend.carbonDateTime" :locale="lang" :masks="masks" @input="handleInputCarbonDateTime">
									<template v-slot="{ inputValue, togglePopover }">
										<b-input-group>
											<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
											<b-input-group-append>
												<b-input-group-text class="cursor-pointer" @click="togglePopover">
													<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</template>
								</v-date-picker>
							</b-form-group>
						</b-col>
						<b-col v-if="disableForm === true" sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(65, 'Date')">
								<b-input-group>
									<b-form-input :value="dateOfExternal" class="bg-color-grams-hair" disabled />
									<b-input-group-append>
										<b-input-group-text>
											<component :is="getLucideIcon('Calendar')" :size="16" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(941, 'Categories')">
								<treeselect
									:options="mapCategories"
									v-model="dataToSend.category"
									:disable-branch-nodes="true"
									@input="handleChangeCategories"
									@select="handleSelectCategorie"
								>
									<div slot="value-label" slot-scope="{ node }" :title="node.label">
										<div>{{ node.label }}</div>
									</div>
									<div slot="option-label" slot-scope="{ node }">
										<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
									</div>
								</treeselect>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="4" lg="4" xl="4">
							<supplier-selector
								v-if="disableForm === false"
								:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
								:label="$screen.width > 576 ? FormMSG(481, 'Supplier') : ''"
								:placeholder="FormMSG(13, 'Filter by name')"
								:addlabel="FormMSG(5442, 'New supplier')"
								:update-label="FormMSG(40, 'Update')"
								:show-map="false"
								:type="null"
								:supplier-id="dataToSend.supplierId"
								use-new-design
								version="1.0"
								class="mb-3"
								@change="handleSupplierChange"
							/>
							<b-form-group v-if="disableForm === true" :label="FormMSG(481, 'Supplier')">
								<b-form-input :value="dataToReview.supplier.name" :class="{ 'bg-color-grams-hair': forCreate === false }" disabled />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(454, 'Amount excl. tax')">
								<b-input-group>
									<b-form-input
										v-model="$v.dataToSend.amount.$model"
										:class="{ 'bg-color-grams-hair': disableForm }"
										:disabled="disableForm"
										@input="handleChangeAmount"
										@change="emitChange"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(455, 'VAT amount')">
								<b-input-group>
									<b-form-input
										v-model="$v.dataToSend.amountVat.$model"
										:disabled="disableForm"
										:class="{ 'bg-color-grams-hair': disableForm }"
										@input="handleChangeAmountVat"
										@change="emitChange"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="4" lg="4" xl="4">
							<b-form-group :label="FormMSG(456, 'Total amount incl. tax')">
								<b-input-group>
									<b-form-input
										v-model="$v.dataToSend.amountTotal.$model"
										@input="handleChangeAmountTotal"
										@change="emitChange"
										:disabled="disableForm"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<input type="file" style="display: none" ref="additional-detail-file" @change="onFilePicked" />
				</div>
			</div>
		</fieldset>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import GreenLocationForm from './form/GreenLocationForm';
import GreenAccomodationForm from './form/GreenAccomodationForm';
import GreenExpenseForm from './form/GreenExpenseForm';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { getFileExtension } from '@/shared/helpers';
import { Carousel, Slide } from 'vue-carousel';
import _ from 'lodash';
import moment from 'moment';
import { removeAttributeTree } from '@/shared/utils';
import SupplierSelector from '@/components/SupplierSelector';
import CurrencySvg from '@/components/Svg/Currency';
import { validationMixin } from 'vuelidate';
import { decimal } from 'vuelidate/lib/validators';
import GreenTransportForm from './form/GreenTransportForm.vue';
import GreenWasteForm from './form/GreenWasteForm.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import GreenPremiseForm from './form/GreenPremiseForm.vue';
import GreenBudgetDetailsForm from './form/GreenBudgetDetailsForm.vue';

const SOURCE_TYPES = ['', 'location', 'accomodation', 'expense'];

export default {
	name: 'AdditionalDetail',
	components: {
		GreenBudgetDetailsForm,
		GreenPremiseForm,
		Treeselect,
		GreenWasteForm,
		GreenTransportForm,
		GreenExpenseForm,
		GreenAccomodationForm,
		GreenLocationForm,
		'v-date-picker': DatePicker,
		Carousel,
		Slide,
		SupplierSelector,
		CurrencySvg
	},
	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		title: { type: String, default: '', required: true },
		source: { type: Number, default: 1, required: false },
		forCreate: { type: Boolean, default: false, required: false },
		dataToReview: { type: Object, default: () => ({}), required: false },
		disableForm: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			masks: {
				input: 'DD/MM/YYYY'
			},
			sourceType: 1,
			dataToEdit: {},
			dataToSend: {
				supplierId: null,
				amount: 0,
				amountVat: 0,
				amountTotal: 0,
				category: null,
				carbonDateTime: new Date(),
				costCenter: ''
			},

			image: null,
			imageUrl: '',
			imageUrls: [],

			imgAction: {
				hoverAction: false
			},

			isSupplierUpdate: false,
			categories: []
		};
	},

	validations: {
		dataToSend: {
			amount: { decimal: decimal },
			amountVat: { decimal: decimal },
			amountTotal: { decimal: decimal }
		}
	},

	watch: {
		dataToReview: {
			handler(newVal) {
				this.imageUrls = [];
				if (!_.isNil(newVal)) {
					this.dataToEdit = newVal;
					this.dataToSend.carbonDateTime = newVal.carbonDateTime;
					this.dataToSend.amount = !_.isNil(newVal.amount) ? parseFloat(newVal.amount).toFixed(2) : 0;
					this.dataToSend.amountVat = !_.isNil(newVal.amountVat) ? parseFloat(newVal.amountVat).toFixed(2) : 0;
					this.dataToSend.amountTotal = !_.isNil(newVal.amountTotal) ? parseFloat(newVal.amountTotal).toFixed(2) : 0;
					this.dataToSend.supplierId = newVal.supplierId;
					this.dataToSend.category = newVal.category;
					this.dataToSend.costCenter = newVal.costCenter;

					if (newVal.documentXid.length > 0) {
						this.imageUrl = `${process.env.VUE_APP_GQL}/images/${newVal.documentXid[0]}`;
						newVal.documentXid.map((option) => {
							this.imageUrls.push({
								hoverAction: false,
								imgSrc: `${process.env.VUE_APP_GQL}/images/${option}`,
								fromDb: true,
								xid: option,
								file: null
							});
						});
					}
				}
			},
			immediate: true,
			deep: true
		},
		source: {
			handler(newVal) {
				this.sourceType = newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		mapCategories() {
			this.categories = removeAttributeTree(this.categories, 'children', null);
			let options = [{ id: 0, label: this.FormMSG(7, 'Select an categories'), isDisabled: true }, ...this.categories];
			return options;
		},
		amountTotal() {
			return parseFloat(this.dataToSend.amount) + parseFloat(this.dataToSend.amountVat);
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (_.isNil(this.currentLocation) || parseInt(this.currentLocation.ownerSupplierId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.dataToSend.supplierId;
		},
		dateOfExternal() {
			if (!_.isNil(this.dataToSend.carbonDateTime)) {
				return moment(new Date(this.dataToSend.carbonDateTime)).format('dddd, DD MMMM YYYY');
			}

			return '';
		}
	},

	async created() {
		await this.getCategories();
	},

	methods: {
		handleSelectCategorie({ data }) {
			this.dataToSend.costCenter = data.costCenter;

			this.emitChange();
		},
		handleChangeCategories(value, item) {
			if (value !== undefined) {
				this.dataToSend.category = value;
			} else {
				this.dataToSend.category = null;
			}

			this.emitChange();
		},
		async getCategories() {
			this.categories = [];
			let result = await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false);
			this.categories = result;
		},
		async handleDeletePicture(index) {
			const action = async () => {
				if (this.imageUrls[index].fromDb === false) {
					this.imageUrls = this.imageUrls.filter((option) => option !== this.imageUrls[index]);
				} else {
					await this.$axios.$get(`/removeimage/${this.imageUrls[index].xid}`);
					this.imageUrls = this.imageUrls.filter((option) => option !== this.imageUrls[index]);
					this.$emit('additional-detail:remove-file', this.imageUrls[index].xid);
				}

				this.emitImagePicked();
			};

			this.confirmModal(action, this.FormMSG(814, 'Are you sure ?'));
		},
		getFileExtensionB64(valueB64) {
			let mimeType = valueB64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

			return mimeType;
		},
		showPicture(index) {
			const images = this.imageUrls.map((img) => {
				if (img.fromDb === true) {
					return {
						xid: img.xid,
						src: `${process.env.VUE_APP_GQL}/images/${img.xid}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img.xid,
						ext: getFileExtension(img.xid)
					};
				} else {
					return {
						xid: '',
						src: img.imgSrc,
						thumb: img.imgSrc,
						ext: this.getFileExtensionB64(img.imgSrc)
					};
				}
			});

			this.$previewImages({
				images,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},
		handleMouseLeave(image) {
			image.hoverAction = false;
		},
		handleMouseEnter(image) {
			image.hoverAction = true;
		},
		onPickFile() {
			this.$refs['additional-detail-file'].click();
		},
		onFilePicked(e) {
			const files = e.target.files;
			let filename = files[0].name;
			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
				this.imageUrls.push({
					hoverAction: false,
					imgSrc: fileReader.result,
					fromDb: false,
					xid: '',
					file: files[0]
				});

				this.emitImagePicked();
			});
			fileReader.readAsDataURL(files[0]);
			this.image = files[0];
		},
		emitImagePicked() {
			let result = [];
			this.imageUrls.map((option) => {
				if (option.fromDb === false) {
					result.push(option.file);
				}
			});

			this.$emit('additional-detail:image-picked', result);
		},
		handleChangeAmount(payload) {
			// let amountTotalIsChanged = false;
			// if (this.$v.dataToSend.amount.$error === false) {
			// 	if (this.$v.dataToSend.amountVat.$error === false && parseFloat(this.dataToSend.amountVat) > 0) {
			// 		this.dataToSend.amountTotal = parseFloat(payload) + parseFloat(this.dataToSend.amountVat);
			// 		amountTotalIsChanged = true;
			// 	}
			// 	if (this.$v.dataToSend.amountTotal.$error === false && parseFloat(this.dataToSend.amountTotal) > 0 && amountTotalIsChanged === false) {
			// 		this.dataToSend.amountVat = parseFloat(this.dataToSend.amountTotal) - parseFloat(payload);
			// 	}
			// }
			this.dataToSend.amountTotal = (parseFloat(payload) + parseFloat(this.dataToSend.amountVat)).toFixed(2);

			// this.emitChange();
		},
		handleChangeAmountVat(payload) {
			let amountTotalChanged = false;
			if (this.$v.dataToSend.amountVat.$error === false) {
				if (!_.isNaN(parseFloat(this.dataToSend.amount)) && parseFloat(this.dataToSend.amount) > 0) {
					this.dataToSend.amountTotal = (parseFloat(this.dataToSend.amount) + parseFloat(payload)).toFixed(2);
					amountTotalChanged = true;
				}
				if (this.$v.dataToSend.amountTotal.$error === false && parseFloat(this.dataToSend.amountTotal) > 0 && amountTotalChanged === false) {
					this.dataToSend.amount = (parseFloat(this.dataToSend.amountTotal) - parseFloat(payload)).toFixed(2);
				}
			}
			this.dataToSend.amountTotal = (parseFloat(this.dataToSend.amount) + parseFloat(payload)).toFixed(2);

			// this.emitChange();
		},
		handleChangeAmountTotal(payload) {
			// let amountChanged = false;
			// if (this.$v.dataToSend.amountTotal.$error === false) {
			// 	if (this.$v.dataToSend.amountVat.$error === false && parseFloat(this.dataToSend.amountVat) > 0) {
			// 		this.dataToSend.amount = parseFloat(payload) - parseFloat(this.dataToSend.amountVat);
			// 		amountChanged = true;
			// 	}
			// 	if (!_.isNaN(parseFloat(this.dataToSend.amount)) && parseFloat(this.dataToSend.amount) > 0 && amountChanged === false) {
			// 		this.dataToSend.amountVat = parseFloat(payload) - parseFloat(this.dataToSend.amountVat);
			// 	}
			// }
			this.dataToSend.amount = (parseFloat(payload) - parseFloat(this.dataToSend.amountVat)).toFixed(2);

			// this.emitChange();
		},
		handleSupplierChange({ id, contactName, email, phone, companyType }) {
			this.dataToSend.supplierId = id;

			this.emitChange();
		},
		handleUnselectSupplier() {
			// this.dataToSend.supplierId = null;

			this.emitChange();
		},
		handleInputCarbonDateTime(payload) {
			this.dataToSend.carbonDateTime = payload;
			this.emitChange();
		},
		emitChange() {
			this.dataToSend.amount = parseFloat(this.dataToSend.amount);
			this.dataToSend.amountVat = parseFloat(this.dataToSend.amountVat);
			this.dataToSend.amountTotal = parseFloat(this.dataToSend.amountTotal);

			this.$emit('additional-detail:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
