<template>
	<errorContainer :error="error">
		<transition name="slide">
			<div style="position: relative">
				<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
					<div class="form">
						<b-row>
							<b-col>
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(18, 'Green table') }}</h1>
							</b-col>
						</b-row>
						<div ref="containerGreenList">
							<b-row class="mt-3">
								<b-col class="p-0">
									<b-card no-body>
										<b-card-text>
											<b-row>
												<b-col>
													<b-alert :show="stateError.show" variant="danger" dismissible @dismissed="handleDismissedError">
														{{ stateError.msg }}
													</b-alert>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<b-alert :show="projectEnded" variant="warning">
														<component :is="getLucideIcon('AlertTriangle')" :size="16" />
														{{ FormMSG(841, 'Project end date has passed ') }} ({{ endDateProject | formatDate('DD-MM-YYYY') }})
													</b-alert>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<filter-budget-advanced
														hide-expense-details-section
														hide-supplier-section
														hide-person-section
														:hide-entry-detail-section="false"
														hide-button-export
														hide-button-refresh-data
														:label-expense-details="FormMSG(148, 'Expense details')"
														:filter-type="FILTER_TYPE.GREEN_TABLE"
														:is-carbon="true"
														@filter-budget-advanced:have-filter="handlerFilterBudgetAdvancedHaveFilter"
													/>
												</b-col>
											</b-row>
											<b-row v-if="carbonTemplateExist === true">
												<b-col>
													<div class="float-right">
														<b-button
															variant="custom-primary-blue"
															:disabled="loadingRunAutomation"
															@click="handleClickRunAutomation"
														>
															<b-spinner v-if="loadingRunAutomation" small class="mr-3" />
															<component :is="getLucideIcon('Play')" :size="16" />
															{{ FormMSG(197, 'Run automations') }}
														</b-button>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<div class="d-flex row align-items-center mt-3 hide-on-tablet">
														<b-col md="12">
															<div class="d-flex justify-content-between">
																<div class="row pl-3">
																	<div class="text-color-australien fs-16 fw-600">
																		<div class="d-flex align-items-center">
																			<div class="ml-2 pt-1">
																				0 - {{ pagination.offset }} {{ FormMSG(519, 'on') }} {{ totalRecord }}
																				{{ FormMSG(520, 'records') }}
																			</div>
																		</div>
																	</div>
																	<b-button
																		class="ml-3"
																		:disabled="pagination.offset === totalRecord"
																		variant="outline-primary"
																		@click="handleShowMore()"
																	>
																		{{ FormMSG(954, 'Load More') }}
																	</b-button>
																</div>
																<div>
																	<b-button
																		v-if="projectEnded === false"
																		variant="primary"
																		class="mr-2"
																		@click="handleClickNewEntry"
																	>
																		<component :is="getLucideIcon('Plus')" :size="16" />
																		{{ FormMSG(547, 'New entry') }}
																	</b-button>
																	<b-button
																		variant="custom-primary-blue"
																		:disabled="greenListToReview.length === 0"
																		@click="handleClickReviewSelection"
																	>
																		<component :is="getLucideIcon('Check')" :size="16" />
																		{{ FormMSG(548, 'Review selection') }}
																	</b-button>
																</div>
															</div>
														</b-col>
													</div>
												</b-col>
											</b-row>
											<b-row class="mt-3">
												<b-col id="containerGreenTable" ref="containerGreenTable">
													<b-table
														v-if="$screen.width >= 992"
														selected-variant="primary"
														hover
														selectable
														select-mode="single"
														responsive="sm"
														sticky-header="700px"
														:items="greenList"
														style="text-align: left"
														:fields="greenTableFields"
														bordered
														striped
														small
														head-variant="dark"
														:empty-text="FormMSG(250, 'No data found')"
														show-empty
														tbody-tr-class="p-2"
														ref="table-green"
													>
														<template #head(actionSelected)="{}">
															<b-form-checkbox
																id="selectAllGreenList"
																v-if="greenList.length > 0"
																v-model="selectAll"
																size="lg"
																@change="handleInputSelectAll"
															/>
														</template>
														<template #head(description)="data">
															<div>{{ data.label }}</div>
															<b-form-group class="mb-0">
																<b-input-group v-if="$screen.width >= 992">
																	<b-form-input
																		v-model="filterInputModel"
																		type="text"
																		id="filterInput"
																		:placeholder="FormMSG(37, 'Type to Search')"
																		@change="handleChangeFilter"
																		@keyup.enter="handleKeydowEnterFilter"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text class="btn-search-filter">
																			<component
																				:is="getLucideIcon('Search')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				v-if="filter.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilter"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(supplier)="data">
															<div>{{ data.label }}</div>
															<b-form-group class="mb-0">
																<b-input-group v-if="$screen.width >= 992">
																	<b-form-input
																		v-model="filterInputSupplierKeyword"
																		type="text"
																		id="filterInput"
																		:placeholder="FormMSG(37, 'Type to Search')"
																		@change="handleChangeSupplierFilter"
																		@keyup.enter="handleKeydowEnterFilter"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text class="btn-search-filter">
																			<component
																				:is="getLucideIcon('Search')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				v-if="filterSupplierKeyword.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetSupplierKeyWordFilter"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(costCenter)="data">
															<div>{{ data.label }}</div>
															<b-form-group class="mb-0">
																<b-input-group v-if="$screen.width >= 992">
																	<b-form-input
																		v-model="filterInputCostCenter"
																		type="text"
																		id="filterInput"
																		:placeholder="FormMSG(37, 'Type to Search')"
																		@change="handleChangeCostCenterFilter"
																		@keyup.enter="handleKeydowEnterFilter"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text class="btn-search-filter">
																			<component
																				:is="getLucideIcon('Search')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				v-if="filterCostCenter.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#000000"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetCostCenterFilter"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>

														<template #cell(verified)="{ item }">
															<div class="wrap-status">
																<div
																	class="status fw-700"
																	:class="{
																		validated: item.verified === 3,
																		'not-submitted': item.verified === 0,
																		pending: item.verified === 2,
																		info: item.verified === 1
																	}"
																	style="font-size: 0.7rem"
																>
																	<div class="text-center">
																		{{ getLabelVerifiedStatus(item.verified) }}
																	</div>
																</div>
															</div>
														</template>
														<template #cell(actionSelected)="data">
															<b-form-checkbox
																v-model="data.item.checked"
																size="lg"
																@change="handleChangeItemChecked($event, data.item, data.index)"
															/>
														</template>
														<template #cell(date)="{ item }">
															<div>
																{{ item.date | formatDate('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(amount)="{ item }">
															<div>
																{{ item.amount | formatCurrency('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(coTwo)="{ item }">
															<div>
																{{ item.coTwo | formatRendKg() }}
															</div>
														</template>
														<template #cell(coTwoDetails)="{ item, index }">
															<div>
																<div class="d-flex" style="padding-top: 2px">
																	<div v-if="getValueToolTipCoTwoDetails(item, index) !== ''">
																		<button
																			v-if="item.coTwoCategory > 0"
																			:id="`popover-details-carbon-${item.id}`"
																			class="btn-transparent text-color-blue-streak"
																		>
																			<component :is="getLucideIcon('Info')" :size="16" />
																		</button>
																	</div>
																	<div>{{ getValueCoTwoDetails(item) }}</div>
																</div>
																<b-popover
																	v-if="getValueToolTipCoTwoDetails(item, index) !== '' && item.coTwoCategory > 0"
																	:target="`popover-details-carbon-${item.id}`"
																	triggers="hover"
																	:placement="index === 0 || index === 1 || index === 2 ? 'left' : 'top'"
																>
																	<template #title>
																		<div>
																			<component :is="getLucideIcon('Info')" :size="16" color="#225cbd" />
																			{{ FormMSG(1547, 'Information') }}
																		</div>
																	</template>

																	<div v-html="getValueToolTipCoTwoDetails(item, index)" />
																</b-popover>
															</div>
														</template>
														<template #cell(option)="{ item, index }">
															<div class="d-flex justify-content-center dropdown-custom" style="min-width: 60px">
																<b-dropdown
																	id="dropdown-green-action"
																	no-caret
																	dropleft
																	boundary="window"
																	class="btn-transparent"
																	variant="none"
																	size="sm"
																	lazy
																	@toggle="handleClickContextTable(item, index)"
																>
																	<template #button-content>
																		<component :is="getLucideIcon('MoreVertical')" :size="16" />
																	</template>
																	<b-dropdown-item @click="handleClickReview(item, index)">
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('CheckCircle')" :size="16" />&nbsp;{{
																				FormMSG(259, 'Review')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item
																		v-if="showResetStatusMenu(item) === true"
																		@click="handleClickResetStatus(item, index)"
																	>
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('RotateCcw')" :size="16" />&nbsp;{{
																				FormMSG(289, 'Reset status')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item
																		v-if="item.templateId > 0"
																		@click="handleClickViewAutomation(item.templateId)"
																	>
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('Regex')" :size="16" />&nbsp;{{
																				FormMSG(291, 'View automation')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item v-if="item.haveDocument" @click="showPicture(+item.id)">
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('FileImage')" :size="16" />&nbsp;{{
																				FormMSG(252, 'See picture')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item v-if="item.entityType === 8" @click="handleClickDeleteExternal(item)">
																		<span class="text-color-burning-tomato">
																			<component :is="getLucideIcon('Trash2')" :size="16" />&nbsp;{{
																				FormMSG(260, 'Remove')
																			}}
																		</span>
																	</b-dropdown-item>
																</b-dropdown>
															</div>
														</template>
													</b-table>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="6">
													<div class="d-flex">
														<b-button
															size="md"
															variant="custom-outline-gray"
															class="mr-2"
															:disabled="deleteDisabled || loadingDeleteAction"
															@click="handleClickDeleteExternalSources"
														>
															<b-spinner v-if="loadingDeleteAction" small />
															{{ FormMSG(158, 'Delete') }}
														</b-button>
														<b-dropdown
															id="dropdown-action-selection"
															variant="outline-primary"
															:disabled="greenListToReview.length === 0 || loadingActionSelection"
															lazy
														>
															<template #button-content>
																<b-spinner v-if="loadingActionSelection" small />
																{{ FormMSG(569, 'Change selection status') }}
															</template>
															<b-dropdown-item @click="resetStatusSelection">
																<div class="d-flex">
																	<div class="mr-2">
																		<component :is="getLucideIcon('RotateCcw')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(612, 'Reset selection status') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="setStatusToReviewSelection">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('UserCheck')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(613, 'Mark selection as TO REVIEW') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickMarkReviewedSelections">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('Check')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(614, 'Mark selection as REVIEWED') }}
																	</div>
																</div>
															</b-dropdown-item>
														</b-dropdown>
														<!--														<b-button-->
														<!--															size="md"-->
														<!--															variant="blue-sapphire"-->
														<!--															:disabled="greenListToReview.length === 0"-->
														<!--															@click="handleClickMarkReviewedSelections"-->
														<!--														>-->
														<!--															<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />-->
														<!--															<b-spinner v-if="loadingForMarkReview === true" small />-->
														<!--															{{ FormMSG(755, 'Mark as reviewed selection') }}-->
														<!--														</b-button>-->
													</div>
												</b-col>
											</b-row>
										</b-card-text>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>

					<review-dialog
						:open="showReviewDialog"
						:for-create="createNewGreen"
						:source="sourceType"
						:data-to-review="dataToReview"
						:data-length="lengthDataToReview"
						:current-index="currentIndexDataToReview"
						@review-dialog:before="handleReviewDialogBefore"
						@review-dialog:after="handleReviewDialogAfter"
						@review-dialog:submit="handleReviewDialogSubmit"
						@review-dialog:update-success="handleReviewDialogUpdateSuccess"
						@review-dialog:reviewed="handleReviewDialogReviewed"
						@review-dialog:automation-end="handleReviewDialogAutomationEnd"
						@review-dialog:reset-status="handleReviewDialogResetStatus"
						@review-dialog:close="handleReviewDialogClose"
					/>

					<carbon-template-dialog
						:open="showCarbonTemplateDialog"
						:data-to-edit="carbonTemplateData"
						@carbon-template-dialog:close="handleCarbonTemplateDialogClose"
					/>

					<view-log-automation-dialog :open="showViewLogAutomation" :log-data="logAutomation" @view-log-automation:close="handleViewLogAutomation" />

					<run-automation-option-dialog
						:open="showRunAutomationOption"
						@run-automation-option-dialog:close="showRunAutomationOption = false"
						@run-automation-option-dialog:choice="handleRunAutomationOptionDialogChoice"
					/>

					<new-entry-option-dialog
						:open="showNewEntryOptionDialog"
						@new-entry-option:close="showNewEntryOptionDialog = false"
						@new-entry-option:create="handleNewEntryOptionCreate"
						@new-entry-option:success="handleNewEntryOptionSuccess"
						@new-entry-option:review-result-import="handleNewEntryOptionReviewResultImport"
					/>

					<multi-selection-confirm-dialog
						:open="showMultiSelectionConfirmDialog"
						:total-data-number="totalRecord"
						:total-data-number-selected="pagination.offset"
						@multi-selection-confirm-dialog:select-all="handleMultiSelectionConfirmDialogSelectAll"
						@multi-selection-confirm-dialog:cancel-selection="handleMultiSelectionConfirmDialogCancelSelection"
						@multi-selection-confirm-dialog:close="handleMultiSelectionConfirmDialogClose"
					/>

					<log-change-status-dialog
						:open="showModalLogChangeStatus"
						:data-list="resultChangeStatus"
						@log-change-status-dialog:close="handleLogChangeStatusDialogClose"
					/>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapActions, mapGetters } from 'vuex';
import { store } from '@/store';
import BudgetMixin from '@/components/Budget/budget.mixin';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import CustomPagination from '@/components/Budget/CustomPagination';
import ReviewDialog from '@/modules/carbonclap/components/green/ReviewDialog';
import {
	getProjectCarbon,
	delCarbonFromExternalSource,
	getTotalNumberProjectCarbon,
	checkIfCarbonTemplateExist,
	runAutomation,
	changeCarbonVerifiedStatus,
	getCarbonTemplate,
	getCarbonFiles,
	checkIfEntityCarbonHaveDocument
} from '@/cruds/carbon.crud';
import { rendCurrency, rendKgCo2, getFileExtension, getFileName } from '~helpers';
import _ from 'lodash';
import moment from 'moment';
import NewEntryOptionDialog from '@/modules/carbonclap/components/green/NewEntryOptionDialog.vue';
import ViewLogAutomationDialog from '@/modules/carbonclap/components/carbon-template/ViewLogAutomationDialog.vue';
import {
	CARBON_VERIFIED_STATUS_MENU,
	CARBON_PRODUCTION_LOGISTIC_TYPE,
	CAR_LODGE_ENERGY_TYPE_MENU,
	ENERGY_TYPE_MENU,
	REASON_ENERGY_TYPE_MENU,
	GEOGRAPHIC_AREA_MENU,
	ACTIVITY_TYPE_MENU,
	POST_PRODUCTION_MEANS_MENU,
	SPE_GENERATOR_ENERGY_TYPE_MENU,
	TRANSPORTATION_MEAN_DETAIL_MENU,
	FILTER_TYPE
} from '@/shared/constants';
import RunAutomationOptionDialog from '@/modules/carbonclap/components/carbon-template/RunAutomationOptionDialog.vue';
import CarbonTemplateDialog from '@/modules/carbonclap/components/carbon-template/CarbonTemplateDialog.vue';
import MultiSelectionConfirmDialog from '@/modules/carbonclap/components/green/MultiSelectionConfirmDialog.vue';
import InfoTooltip from '@/components/LocationService/InfoTooltip.vue';
import LogChangeStatusDialog from '@/modules/carbonclap/components/green/LogChangeStatusDialog.vue';
import { getLocationSetName } from '@/cruds/locations.crud';
import { formatLocalString } from '@/shared/utils';

const LIMIT = 100;

export default {
	name: 'greenList',

	mixins: [languageMessages, globalMixin, BudgetMixin],

	components: {
		FilterBudgetAdvanced,
		CustomPagination,
		ReviewDialog,
		NewEntryOptionDialog,
		ViewLogAutomationDialog,
		RunAutomationOptionDialog,
		CarbonTemplateDialog,
		MultiSelectionConfirmDialog,
		InfoTooltip,
		LogChangeStatusDialog
	},

	data() {
		return {
			currentProject: store.getCurrentProjectConfig(),
			unitsFormMenu: [],
			standardWasteTypeMenus: [],
			setDestructionWasteTypeMenus: [],
			error: {},
			filter: '',
			offset: 0,
			limit: _.cloneDeep(LIMIT),
			perPage: 100,
			greenList: [],
			greenListToReview: [],
			totalRecord: 0,
			filterActive: {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate,
				userDepartment: -1
			},
			getIndexFromListToReview: false,
			selectAll: false,
			showReviewDialog: false,
			createNewGreen: false,
			sourceType: 1,
			dataToReview: {},
			currentIndexDataToReview: 0,
			lengthDataToReview: 0,
			prepareLoading: false,
			showNewEntryOptionDialog: false,
			carbonTemplateExist: false,
			loadingRunAutomation: false,
			logAutomation: {},
			showViewLogAutomation: false,
			filterInputModel: '',
			filterCostCenter: '',
			filterInputCostCenter: '',
			filterSupplierKeyword: '',
			filterInputSupplierKeyword: '',
			loadingForMarkReview: false,
			alreadyLastData: false,
			showRunAutomationOption: false,
			showCarbonTemplateDialog: false,
			carbonTemplateData: {},
			haveFilter: false,
			loadingActionSelection: false,
			loadingDeleteAction: false,
			pagination: {
				offset: 0,
				totalRecords: 0
			},
			showMultiSelectionConfirmDialog: false,
			resetLoadData: true,
			stateError: {
				show: false,
				msg: ''
			},
			resultChangeStatus: [],
			showModalLogChangeStatus: false,
			FILTER_TYPE
		};
	},
	watch: {
		$route: {
			handler() {
				setTimeout(() => {
					let tableGreen = this.$refs['table-green'].$el;
					tableGreen.removeEventListener('scroll', this.handleScroll);
					tableGreen.addEventListener('scroll', this.handleScroll);
				}, 200);
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			listFilters: 'global-filter/listFilters',
			paginationFilter: 'global-filter/paginationFilter'
		}),
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		},
		deleteDisabled() {
			let result = true;

			for (let i = 0; i < this.greenListToReview.length; i++) {
				const element = this.greenListToReview[i];
				if (element.entityType === 8) {
					result = false;
					break;
				}
			}
			return result;
		},
		projectEnded() {
			const endProject = new Date(this.currentProject.endDate);
			if (endProject.getTime() < new Date().getTime()) {
				return true;
			}

			return false;
		},
		endDateProject() {
			return this.currentProject.endDate;
		},
		filterGreenTable() {
			if (!_.isNil(this.filterBudget.forGreenTable)) {
				const payload = this.filterBudget.forGreenTable;

				let startDate = null;
				let endDate = null;

				if (!_.isNil(payload.startDate)) {
					startDate = moment(payload.startDate).format('YYYY-MM-DD');
				}

				if (!_.isNil(payload.endDate)) {
					endDate = moment(payload.endDate).format('YYYY-MM-DD');
				}

				const filterToReturn = this.returnFilter(this.filterBudget.forGreenTable);

				return {
					...filterToReturn,
					startDate,
					endDate
				};
			}
			return {
				startDate: null,
				endDate: null,
				departmentId: -1
			};
		},
		paginationGreenTable() {
			return this.paginationFilter.forGreenTable;
		},
		greenTableFields() {
			let result = [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'strDate',
					label: this.FormMSG(165, 'Date'),
					formatter: (value) => {
						return moment(new Date(value.split('T')[0])).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'w-75-px'
				},
				{
					key: 'entityType',
					label: this.FormMSG(200, 'Source'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(11006, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(201, 'Description'),
					sortable: false
				},
				{
					key: 'coTwoCategory',
					label: this.FormMSG(202, 'CO2 type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1334, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'coTwoDetails',
					label: this.FormMSG(601, 'CO2 detail'),
					sortable: false
				},
				{
					key: 'supplier.name',
					label: this.FormMSG(203, 'Supplier'),
					sortable: true
				},
				// {
				// 	key: 'fullName',
				// 	label: this.FormMSG(204, 'Full Name'),
				// 	formatter: (value, key, item) => {
				// 		return item.user.name + ' ' + item.user.firstName;
				// 	},
				// 	sortable: true
				// },
				{
					key: 'costCenter',
					label: this.FormMSG(267, 'Cost center')
				},
				{
					key: 'verified',
					label: this.FormMSG(285, 'Already reviewed?'),
					formatter: (value) => {
						return value === true ? this.FormMSG(425, 'Yes') : this.FormMSG(426, 'No');
					},
					class: 'text-center',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(207, 'Amount'),
					formatter: (value) => {
						return rendCurrency(parseFloat(value));
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'carbonValues.kgCoTwo',
					label: this.FormMSG(208, 'CO2'),
					formatter: (value, key, item) => {
						return rendKgCo2(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'option',
					label: this.FormMSG(209, 'Option'),
					sortable: false,
					class: 'text-center w-75-px'
				}
			];

			return result;
		}
	},
	async mounted() {
		this.$bus.$on('filter-budget-advanced:change', async (payload) => {
			await this.handleFilterBudgetAdvancedChange(payload);
		});

		this.$bus.$on('filter-budget-advanced:clear-filters', async (payload) => {
			await this.handleFilterBudgetAdvancedChange({});
		});

		// await this.handleScroll();
	},
	destroyed() {
		// shutdown event bus
		this.$bus.$off('filter-budget-advanced:change');
		this.$bus.$off('filter-budget-advanced:clear-filters');

		clearTimeout();
	},
	async created() {
		this.unitsFormMenu = this.FormMenu(1452);
		this.standardWasteTypeMenus = this.FormMenu(1427);
		this.setDestructionWasteTypeMenus = this.FormMenu(1428);
		await this.getMyFilterActive(5);
		await this.getListFilters(5);
		await this.checkIfCarbonTemplateExist();

		await this.getGreenTableList();
	},
	methods: {
		...mapActions({
			getListFilters: 'global-filter/getListFilters',
			getMyFilterActive: 'global-filter/getMyFilterActive'
		}),
		handleMultiSelectionConfirmDialogCancelSelection() {
			this.greenListToReview = [];
			for (let i = 0; i < this.greenList.length; i++) {
				this.greenList[i].checked = false;
			}
			this.selectAll = false;
		},
		async handleRowHovered(item, index) {
			if (item.coTwoCategory === 1 || item.coTwoCategory === 15) {
				this.greenList[index].setNameLocation = await getLocationSetName(item.locationId);
			}
		},
		handleLogChangeStatusDialogClose() {
			this.resultChangeStatus = [];
			this.showModalLogChangeStatus = false;
		},
		handleDismissedError() {
			this.stateError.show = false;
			this.stateError.msg = '';
		},
		async handleClickContextTable(item, index) {
			this.greenList[index].haveDocument = await checkIfEntityCarbonHaveDocument(item.entityId, item.entityType);
		},
		async handleMultiSelectionConfirmDialogSelectAll() {
			let reviewStatus = [];
			let arrayType = [];
			this.getIndexFromListToReview = false;
			this.greenListToReview = [];

			if (!_.isNil(this.filterGreenTable.reviewStatus) && this.filterGreenTable.reviewStatus.length > 0) {
				this.filterGreenTable.reviewStatus.map((option) => {
					reviewStatus.push({ status: +option });
				});
			}

			if (!_.isNil(this.filterGreenTable.arrayType) && this.filterGreenTable.arrayType.length > 0) {
				this.filterGreenTable.arrayType.map((option) => {
					arrayType.push({ type: +option });
				});
			}

			let newFilterActive = {
				...this.filterGreenTable,
				recordOffSet: 0,
				recordLimit: this.totalRecord,
				keyWord: this.filter,
				costCenterKeyWord: this.filterCostCenter,
				supplierKeyWord: this.filterSupplierKeyword
			};

			if (reviewStatus.length > 0) {
				newFilterActive = _.assign(newFilterActive, {
					reviewStatus
				});
			}

			if (arrayType.length > 0) {
				newFilterActive = _.assign(newFilterActive, {
					arrayType
				});
			}

			this.greenListToReview = await getProjectCarbon(newFilterActive);
		},
		handleMultiSelectionConfirmDialogClose() {
			this.showMultiSelectionConfirmDialog = false;
		},
		provideStandardWasteType() {
			return [
				{
					value: 'kgCardboardWaste',
					label: this.standardWasteTypeMenus[0].text,
					unit: this.unitsFormMenu[this.currentProject.cardboardUnit].text
				},
				{
					value: 'kgPaperWaste',
					label: this.standardWasteTypeMenus[1].text,
					unit: this.unitsFormMenu[this.currentProject.paperUnit].text
				},
				{
					value: 'kgPlasticWaste',
					label: this.standardWasteTypeMenus[2].text,
					unit: this.unitsFormMenu[this.currentProject.plasticUnit].text
				},
				{
					value: 'kgFoodWaste',
					label: this.standardWasteTypeMenus[3].text,
					unit: this.unitsFormMenu[this.currentProject.foodUnit].text
				},
				{
					value: 'kgGeneralWaste',
					label: this.standardWasteTypeMenus[4].text,
					unit: this.unitsFormMenu[this.currentProject.generalWasteUnit].text
				},
				{
					value: 'kgGlassWaste',
					label: this.standardWasteTypeMenus[5].text,
					unit: this.unitsFormMenu[this.currentProject.glassUnit].text
				}
			];
		},
		provideSetDestructionWasteType() {
			return [
				{
					value: 'kgPaintWaste',
					label: this.setDestructionWasteTypeMenus[0].text,
					unit: this.unitsFormMenu[this.currentProject.paintSpecialWasteUnit].text
				},
				{
					value: 'kgWoodWaste',
					label: this.setDestructionWasteTypeMenus[1].text,
					unit: this.unitsFormMenu[this.currentProject.woodUnit].text
				},
				{
					value: 'kgSteelWaste',
					label: this.setDestructionWasteTypeMenus[2].text,
					unit: this.unitsFormMenu[this.currentProject.steelUnit].text
				},
				{
					value: 'kgPlasticWaste',
					label: this.setDestructionWasteTypeMenus[3].text,
					unit: this.unitsFormMenu[this.currentProject.plasticDestructionUnit].text
				}
			];
		},
		provideWasteTypeWithSameUnit(type) {
			const standardWasteType = this.provideStandardWasteType();
			const setDestructionWasteType = this.provideSetDestructionWasteType();
			const finalResult = [];

			for (const elem of this.unitsFormMenu) {
				let resultItem = [];
				if (type === 1) {
					resultItem = standardWasteType.filter((wasteType) => wasteType.unit === elem.text);
				} else {
					resultItem = setDestructionWasteType.filter((wasteType) => wasteType.unit === elem.text);
				}
				if (resultItem[0]) finalResult.push(resultItem);
			}

			return finalResult;
		},
		getValueToolTipCoTwoDetails(item, index) {
			const standardWasteType = this.provideStandardWasteType();
			const setDestructionWasteType = this.provideSetDestructionWasteType();
			let result = [];

			if (item.coTwoCategory === 1 || item.coTwoCategory === 15) {
				const locationSetName = item.locationSetName;
				let totalKwh = parseFloat(item.elecMeterEnd) - parseFloat(item.elecMeterStart);

				let totalGas = parseFloat(item.gasMeterEnd) - parseFloat(item.gasMeterStart);
				let totalWater = parseFloat(item.waterMeterEnd) - parseFloat(item.waterMeterStart);

				result.push(`<b>${this.FormMSG(582, 'Location name')}:</b> ${locationSetName}`);

				if (totalKwh > 0) result.push(`<b>${this.FormMSG(583, 'Electricity')}:</b> ${totalKwh} ${this.FormMSG(512, 'kWh')}`);
				if (totalGas > 0) result.push(`<b>${this.FormMSG(1025, 'Gas')}:</b> ${totalGas} ${this.FormMSG(513, 'm3')}`);
				if (totalWater > 0) result.push(`<b>${this.FormMSG(2015, 'Water')}:</b> ${totalWater} ${this.FormMSG(513, 'm3')}`);
				if (item.fuelOilVolumeUsed > 0) result.push(`<b>${this.FormMSG(182, 'Fuel oil')}:</b> ${item.fuelOilVolumeUsed} ${this.FormMSG(183, 'L')}`);
				if (item.generatorFuelType === 0) {
					if (item.generatorVolumeUsed > 0) {
						result.push(
							`<b>${this.FormMSG(1032, 'Generator')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1402, 0)} (${
								item.generatorVolumeUsed
							} ${this.FormMSG(514, 'L')})`
						);
					}
				}
				if (item.generatorFuelType === 1) {
					if (item.generatorVolumeUsed > 0) {
						result.push(
							`<b>${this.FormMSG(1032, 'Generator')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1402, 1)} (${
								item.generatorVolumeUsed
							} ${this.FormMSG(513, 'm3')})`
						);
					}
				}
				if (item.generatorFuelType === 2) {
					if (item.generatorVolumeUsed > 0) {
						result.push(
							`<b>${this.FormMSG(1032, 'Generator')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1402, 2)} (${
								item.generatorVolumeUsed
							} ${this.FormMSG(514, 'L')})`
						);
					}
				}
				if (item.generatorFuelType === 3) {
					if (item.generatorVolumeUsed > 0) {
						result.push(
							`<b>${this.FormMSG(1032, 'Generator')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1402, 3)} (${
								item.generatorVolumeUsed
							} ${this.FormMSG(515, 'kg')})`
						);
					}
				}
			} else if (item.coTwoCategory === 2) {
				let typeAccommodation = this.FormMSG(1067, 'Accommodation');
				const totalNights = item.numberBedroom * item.numberOfNight;
				if (item.entityType === 6 && item.coTwoCategory === 4) {
					typeAccommodation = this.FormMSG(1068, 'Travel');
				}
				result.push(`${this.FormMSG(5021, 'Type')}: ${typeAccommodation}`);
				if (item.numberBedroom > 0) result.push(`<b>${this.FormMSG(1069, 'No. of bedrooms')}:</b> ${item.numberBedroom}`);
				if (item.numberOfNight > 0) result.push(`<b>${this.FormMSG(1070, 'Number of nights')}:</b> ${item.numberOfNight}`);
				if (totalNights > 0) result.push(`<b>${this.FormMSG(1071, 'Total number of nights')}:</b> ${totalNights}`);
			} else if (item.coTwoCategory === 3) {
				result.push(`<b>${this.FormMSG(1032, 'Generator')}:</b> ${item.specialEquipment.name}`);
				result.push(
					`<b>${this.FormMSG(1202, 'Energy Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(
						SPE_GENERATOR_ENERGY_TYPE_MENU,
						item.specialEquipment.energyType
					)}`
				);

				// commented by Lanja
				// reason: https://app.clickup.com/t/86bx6jpv8
				// result.push(`<b>${this.FormMSG(1201, 'Reason')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(REASON_ENERGY_TYPE_MENU, item.type)}`);

				// let labelEnergyType = '';
				// if (item.type === 1 || item.type === 2) {
				// 	labelEnergyType = this.GetTextFromMenuNumberAndMenuValue(ENERGY_TYPE_MENU, item.subType);
				// } else if (item.type === 3) {
				// 	labelEnergyType = this.GetTextFromMenuNumberAndMenuValue(CAR_LODGE_ENERGY_TYPE_MENU, item.subType);
				// }

				// result.push(`<b>${this.FormMSG(1202, 'Energy type')}:</b> ${labelEnergyType}`);

				// let consumptionValue = '';
				// if (item.type === 1 || item.type === 2) {
				// 	if (item.subType === 1 && item.kwh > 0) {
				// 		consumptionValue = `${item.kwh} ${this.FormMSG(512, 'kWh')}`;
				// 	} else if ((item.subType === 2 || item.subType === 5) && item.mcube > 0) {
				// 		consumptionValue = `${item.mcube} ${this.FormMSG(513, 'm3')}`;
				// 	} else if (item.subType === 3 && item.waterVolumeUsed > 0) {
				// 		consumptionValue = `${item.waterVolumeUsed} ${this.FormMSG(513, 'm3')}`;
				// 	} else if (item.subType === 4 && item.liter > 0) {
				// 		consumptionValue = `${item.liter} ${this.FormMSG(514, 'L')}`;
				// 	} else if (item.subType === 6 && item.kg > 0) {
				// 		consumptionValue = `${item.kg} ${this.FormMSG(515, 'kg')}`;
				// 	}
				// } else if (item.type === 3) {
				// 	if ((item.subType === 1 || item.subType === 2) && item.liter > 0) {
				// 		consumptionValue = `${item.liter} ${this.FormMSG(514, 'L')}`;
				// 	} else if (item.subType === 3 && item.mcube > 0) {
				// 		consumptionValue = `${item.mcube} ${this.FormMSG(513, 'm3')}`;
				// 	}
				// }

				// result.push(`<b>${this.FormMSG(1401, 'Consumption')}:</b> ${consumptionValue}`);
			} else if (item.coTwoCategory === 4) {
				const type = this.rendTransportationMean(item.type);
				let subType = '';
				let additionalDetail = '';

				if ([6, 7, 8, 12, 13, 15, 16].includes(item.type)) {
					if ([6, 7, 8].includes(item.type)) {
						subType = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.CAR, item.subType);
					} else if (item.type === 12) {
						subType = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE, item.subType);
					} else if (item.type === 13) {
						subType = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.SCOOTER, item.subType);
					} else if (item.type === 15) {
						subType = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.TRAIN, item.subType);
					} else if (item.type === 16) {
						subType = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.VAN, item.subType);
					}
				}

				if (item.type > 0 && item.subType > 0 && [5, 14, 15].includes(item.subType)) {
					if ([5, 6, 7, 8].includes(item.subType)) {
						additionalDetail = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE_TYPE, item.additionalSubType);
					} else if ([14, 15].includes(item.subType)) {
						additionalDetail = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.CAR, item.additionalSubType);
					}
				} else if (item.type > 0 && item.subType > 0 && [1, 2, 3, 4].includes(item.subType)) {
					additionalDetail = this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_DETAIL_MENU.HIRE_RENTAL, item.additionalSubType);
				}
				result.push(`<b>${this.FormMSG(1073, 'Transportation type')}:</b> ${type}`);
				if (subType !== '') result.push(`<b>${this.FormMSG(1601, 'Detail')}:</b> ${subType}`);
				if (additionalDetail !== '') result.push(`<b>${this.FormMSG(1602, 'Additional detail')}:</b> ${additionalDetail}`);
				if (!isNaN(item.km)) result.push(`<b>${this.FormMSG(1072, 'Km')}:</b> ${item.km} ${this.FormMSG(521, 'km')}`);
				if (item.numberOfVehicule > 0) result.push(`<b>${this.FormMSG(177, 'Number of vehicle')}:</b> ${item.numberOfVehicule}`);
				if (item.navigationDuration > 0)
					result.push(`<b>${this.FormMSG(1075, 'Navigation Duration')}:</b> ${this.convertMinutesToHours(item.navigationDuration)}`);
				if (item.fuelConsumption > 0)
					result.push(`<b>${this.FormMSG(540, 'Fuel consumption')}:</b> ${item.fuelConsumption + ' ' + this.FormMSG(514, 'L')}`);
			} else if (item.coTwoCategory === 5) {
				if (item.type === 1) {
					standardWasteType.forEach((element) => {
						if (item[element.value]) result.push(`<b>${element.label}:</b> ${item[element.value]} (${element.unit})`);
					});
					// if (item.kgCardboardWaste) result.push(`<b>${this.FormMSG(1076, 'Cardboard')}:</b> ${item.kgCardboardWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgPaperWaste) result.push(`<b>${this.FormMSG(1077, 'Paper')}:</b> ${item.kgPaperWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgPlasticWaste) result.push(`<b>${this.FormMSG(1078, 'Plastic')}:</b> ${item.kgPlasticWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgFoodWaste) result.push(`<b>${this.FormMSG(1079, 'Food')}:</b> ${item.kgFoodWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgGeneralWaste) result.push(`<b>${this.FormMSG(1081, 'General waste')}:</b> ${item.kgGeneralWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgGlassWaste) result.push(`<b>${this.FormMSG(1087, 'Glass waste')}:</b> ${item.kgGlassWaste} ${this.FormMSG(515, 'kg')}`);
				}
				if (item.type === 2) {
					setDestructionWasteType.forEach((element) => {
						if (item[element.value]) result.push(`<b>${element.label}:</b> ${item[element.value]} (${element.unit})`);
					});
					// if (item.kgPaintWaste) result.push(`<b>${this.FormMSG(1082, 'Paint')}:</b> ${item.kgPaintWaste} ${this.FormMSG(514, 'L')}`);
					// if (item.kgCardboardWaste) result.push(`<b>${this.FormMSG(1083, 'Cardboard')}:</b> ${item.kgCardboardWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgWoodWaste) result.push(`<b>${this.FormMSG(1084, 'Wood')}:</b> ${item.kgWoodWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgSteelWaste) result.push(`<b>${this.FormMSG(1085, 'Steel')}:</b> ${item.kgSteelWaste} ${this.FormMSG(515, 'kg')}`);
					// if (item.kgPlasticWaste) result.push(`<b>${this.FormMSG(1086, 'Plastic')}:</b> ${item.kgPlasticWaste} ${this.FormMSG(515, 'kg')}`);
				}
			} else if (item.coTwoCategory === 6) {
				if (item.mealVege > 0) result.push(`<b>${this.FormMSG(1090, 'Vegetarian/Vegan meal')}:</b> ${item.mealVege}`);
				if (item.mealRed > 0) result.push(`<b>${this.FormMSG(1091, 'Red meat meals')}:</b> ${item.mealRed}`);
				if (item.mealWhite > 0) result.push(`<b>${this.FormMSG(1092, 'Fish/white meat meals')}:</b> ${item.mealWhite}`);
				if (item.mealNotSpecified > 0) result.push(`<b>${this.FormMSG(1093, 'Not specified')}:</b> ${item.mealNotSpecified}`);
			} else if (item.coTwoCategory === 7) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(11008, item.type)}`);
				if (item.amount > 0) result.push(`<b>${this.FormMSG(207, 'Amount')}:</b> ${rendCurrency(parseFloat(item.amount))}`);
			} else if (item.coTwoCategory === 8) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(11009, item.type)}`);
				if (item.amount > 0) result.push(`<b>${this.FormMSG(207, 'Amount')}:</b> ${rendCurrency(parseFloat(item.amount))}`);
			} else if (item.coTwoCategory === 9) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(11010, item.type)}`);
				if (item.amount > 0) result.push(`<b>${this.FormMSG(207, 'Amount')}:</b> ${rendCurrency(parseFloat(item.amount))}`);
			} else if (item.coTwoCategory === 10) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(11011, item.type)}`);
				if (item.km > 0) result.push(`<b>${this.FormMSG(1099, 'Distance')}:</b> ${item.km} ${this.distanceUnit}`);
				if (item.number > 0) result.push(`<b>${this.FormMSG(1100, 'Duration')}:</b> ${this.convertMinutesToHours(item.number)}`);
				// if ([1, 2, 3, 5].includes(item.type)) {
				// 	if (item.km > 0) result.push(`<b>${this.FormMSG(1099, 'Distance')}:</b> ${item.km} ${this.distanceUnit}`);
				// }
				// if ([4, 6, 7].includes(item.type)) {
				// 	if (item.number > 0) result.push(`<b>${this.FormMSG(1100, 'Duration')}:</b> ${this.convertMinutesToHours(item.number)}`);
				// }
			} else if (item.coTwoCategory === 12) {
				result.push(`<b>${this.FormMSG(1101, 'Car size')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1432, item.type)}`);
				if (item.km > 0) result.push(`<b>${this.FormMSG(1099, 'Distance')}:</b> ${item.km} ${this.distanceUnit}`);
			} else if (item.coTwoCategory === 13) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(11012, item.type)}`);
				if (item.liter > 0) result.push(`<b>${this.FormMSG(1103, 'Quantity')}:</b> ${item.liter} ${this.FormMSG(514, 'L')}`);
			} else if (item.coTwoCategory === 14) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(CARBON_PRODUCTION_LOGISTIC_TYPE, item.type)}`);
				if (item.amount > 0) result.push(`<b>${this.FormMSG(207, 'Amount')}:</b> ${rendCurrency(parseFloat(item.amount))}`);
			} else if (item.coTwoCategory === 18) {
				result.push(this.provideFilmVehicleData(item, 'popup'));
			} else if (item.coTwoCategory === 19) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(1448, item.type)}`);
			} else if (item.coTwoCategory === 20) {
				let detailBull = `<b>${this.FormMSG(1094, 'Type')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(ACTIVITY_TYPE_MENU, item.type)}<br />`;
				detailBull += `<b>${this.FormMSG(1110, 'Country')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(8, item.subType)}<br />`;
				if (item.additionalSubType === 2) {
					detailBull += `
					<b>${this.FormMSG(1111, 'Post-production means')}:</b> ${this.GetTextFromMenuNumberAndMenuValue(POST_PRODUCTION_MEANS_MENU, item.postProductionMeansType)}<br />
					<b>${this.FormMSG(1112, 'Number of working days')}:</b> ${item.numberOfWorkingDays + ' ' + this.FormMSG(400, 'man-day')}<br />
					<b>${this.FormMSG(1113, 'Number of extra-staff days')}:</b> ${item.numberOfExtraStaffDays + ' ' + this.FormMSG(400, 'man-day')}`;
				}
				result.push(detailBull);
			} else if (item.coTwoCategory === 21) {
				result.push(`<b>${this.FormMSG(1094, 'Type')}:</b> ${item.specialEquipment.name}`);
			} else if (item.coTwoCategory === 17) {
				result.push(`
					<b>${this.FormMSG(1114, 'Geographic area')}</b>: ${this.GetTextFromMenuNumberAndMenuValue(GEOGRAPHIC_AREA_MENU, item.type)} <br />
					<b>${this.FormMSG(1115, 'Number of trips')}</b>: ${item.number} ${this.FormMSG(500, 'trips')}
				`);
			}

			return result.join('<br />');
		},
		getValueCoTwoDetails(item) {
			let result = [];
			item.setNameLocation = '';
			if (item.coTwoCategory === 0) {
				return '-';
			} else if (item.coTwoCategory === 1 || item.coTwoCategory === 15) {
				let totalLiter = 0;
				let totalKg = 0;
				let totalKwh = parseFloat(item.consumptionElectricity);

				let totalM3 = parseFloat(item.consumptionGas) + parseFloat(item.consumptionWater);

				if (item.generatorFuelType === 0) totalLiter += parseFloat(item.generatorVolumeUsed);
				if (item.generatorFuelType === 1) totalM3 += parseFloat(item.generatorVolumeUsed);
				if (item.generatorFuelType === 2) totalLiter += parseFloat(item.generatorVolumeUsed);
				if (item.generatorFuelType === 3) totalKg += parseFloat(item.generatorVolumeUsed);

				totalLiter += item.fuelOilVolumeUsed;

				if (totalKwh > 0) result.push(`${totalKwh} ${this.FormMSG(512, 'kWh')}`);

				if (totalM3 > 0) result.push(`${totalM3} ${this.FormMSG(513, 'm3')}`);
				if (totalLiter > 0) result.push(`${totalLiter} ${this.FormMSG(514, 'L')}`);
				if (totalKg > 0) result.push(`${totalKg} ${this.FormMSG(515, 'kg')}`);
			} else if (item.coTwoCategory === 2) {
				const totalNights = parseFloat(item.numberBedroom) * parseFloat(item.numberOfNight);

				if (totalNights > 0) result.push(`${totalNights} ${this.FormMSG(516, 'Night(s)')}`);
			} else if (item.coTwoCategory === 3) {
				// commented by Lanja
				// reason: https://app.clickup.com/t/86bx6jpv8
				// let totalKwh = 0;
				// let totalM3 = 0;
				// let totalLiter = 0;
				// let totalKg = 0;

				// LOCATION && PREMISE
				// if (item.type === 1 || item.type === 2) {
				// 	if (item.subType === 1) {
				// 		totalKwh += item.kwh;
				// 	} else if (item.subType === 2 || item.subType === 3 || item.subType === 5) {
				// 		totalM3 = item.mcube;
				// 	} else if (item.subType === 4) {
				// 		totalLiter = item.liter;
				// 	} else if (item.subType === 6) {
				// 		totalKg = item.kg;
				// 	}
				// }
				// CAR LODGE
				// if (item.type === 3) {
				// 	if (item.subType === 1 || item.subType === 2) {
				// 		totalLiter = item.liter;
				// 	} else if (item.subType === 3) {
				// 		totalM3 = item.mcube;
				// 	}
				// }

				// if (totalKwh > 0) result.push(`${totalKwh} ${this.FormMSG(512, 'kWh')}`);
				// if (totalM3 > 0) result.push(`${totalM3} ${this.FormMSG(513, 'm3')}`);
				// if (totalLiter > 0) result.push(`${totalLiter} ${this.FormMSG(514, 'L')}`);
				// if (totalKg > 0) result.push(`${totalKg} ${this.FormMSG(515, 'kg')}`);

				if (item.kwh > 0) result.push(`${item.kwh} ${this.FormMSG(512, 'kWh')}`);
				if (item.mcube > 0) result.push(`${item.mcube} ${this.FormMSG(513, 'm3')}`);
				if (item.liter > 0) result.push(`${item.liter} ${this.FormMSG(514, 'L')}`);
				if (item.number > 0) result.push(`${item.number} ${this.FormMSG(515, 'kg')}`);
			} else if (item.coTwoCategory === 4) {
				if (!isNaN(item.totalKm)) {
					result.push(`${Math.round(item.km)} ${this.FormMSG(521, 'km')}`);
				}
				if (item.navigationDuration > 0) result.push(this.convertMinutesToHours(item.navigationDuration));
				if (item.fuelConsumption > 0) result.push(item.fuelConsumption + ' ' + this.FormMSG(514, 'L'));
			} else if (item.coTwoCategory === 5) {
				const typesGroup = this.provideWasteTypeWithSameUnit(item.type);
				for (const elem of typesGroup) {
					let total = 0;
					elem.forEach((element) => {
						if (item[element.value]) total += item[element.value];
					});
					if (total !== 0) result.push(`${parseFloat(total)} (${elem[0].unit})`);
				}
				// if (item.type === 1) {
				// 	const totalKg =
				// 		parseFloat(item.kgCardboardWaste) +
				// 		parseFloat(item.kgPaperWaste) +
				// 		parseFloat(item.kgPlasticWaste) +
				// 		parseFloat(item.kgFoodWaste) +
				// 		parseFloat(item.kgGeneralWaste) +
				// 		parseFloat(item.kgGlassWaste);

				// 	if (totalKg > 0) {
				// 		result.push(`${totalKg} ${this.FormMSG(515, 'kg')}`);
				// 	}
				// 	if (parseFloat(item.unitBatterieWaste) > 0) {
				// 		result.push(`${parseFloat(item.unitBatterieWaste)} ${this.FormMSG(1587, 'Batteries')}`);
				// 	}
				// } else if (item.type === 2) {
				// 	const totalLiter = parseFloat(item.kgPaintWaste);
				// 	const totalKg =
				// 		parseFloat(item.kgCardboardWaste) + parseFloat(item.kgWoodWaste) + parseFloat(item.kgSteelWaste) + parseFloat(item.kgPlasticWaste);

				// 	if (totalLiter > 0) result.push(`${totalLiter} ${this.FormMSG(514, 'L')}`);
				// 	if (totalKg > 0) result.push(`${totalKg} ${this.FormMSG(515, 'kg')}`);
				// }
			} else if (item.coTwoCategory === 6) {
				const totalMeals = parseFloat(item.mealVege) + parseFloat(item.mealRed) + parseFloat(item.mealWhite) + parseFloat(item.mealNotSpecified);

				if (totalMeals > 0) result.push(`${totalMeals} ${this.FormMSG(523, 'Meals')}`);
			} else if (item.coTwoCategory === 7) {
				result.push(`${this.GetTextFromMenuNumberAndMenuValue(11008, item.type)}`);
			} else if (item.coTwoCategory === 8) {
				result.push(`${this.GetTextFromMenuNumberAndMenuValue(11009, item.type)}`);
			} else if (item.coTwoCategory === 9) {
				const label = this.GetTextFromMenuNumberAndMenuValue(11010, item.type);
				result.push(`${label}`);
			} else if (item.coTwoCategory === 10) {
				if (!isNaN(item.km)) result.push(`${Math.round(item.km)} ${this.FormMSG(521, 'km')}`);
				if (item.number > 0) result.push(`${this.convertMinutesToHours(item.number)}`);
				// if ([1, 2, 3, 5].includes(item.type)) {
				// 	if (item.km > 0) result.push(`${Math.round(item.km)} ${this.FormMSG(521, 'km')}`);
				// }
				// if ([4, 6, 7].includes(item.type)) {
				// 	if (item.number > 0) result.push(`${this.convertMinutesToHours(item.number)}`);
				// }
			} else if (item.coTwoCategory === 11) {
				if ([2, 3].includes(item.subType)) {
					if (item.liter > 0) result.push(`${item.liter} ${this.FormMSG(514, 'L')}`);
				}
				if (item.subType === 4) {
					if (item.mcube > 0) result.push(`${item.mcube} ${this.FormMSG(513, 'm3')}`);
				}
			} else if (item.coTwoCategory === 12) {
				if (!isNaN(item.km)) result.push(`${Math.round(item.km)} ${this.FormMSG(521, 'km')}`);
			} else if (item.coTwoCategory === 13) {
				if (item.liter > 0) result.push(`${item.liter} ${this.FormMSG(514, 'L')}`);
			} else if (item.coTwoCategory === 14) {
				result.push(`${this.GetTextFromMenuNumberAndMenuValue(CARBON_PRODUCTION_LOGISTIC_TYPE, item.type)}`);
			} else if (item.coTwoCategory === 18) {
				result.push(this.provideFilmVehicleData(item));
			} else if (item.coTwoCategory === 19) {
				result.push(this.GetTextFromMenuNumberAndMenuValue(1448, item.type));
			} else if (item.coTwoCategory === 20) {
				result.push(this.GetTextFromMenuNumberAndMenuValue(ACTIVITY_TYPE_MENU, item.type));
			} else if (item.coTwoCategory === 21) {
				result.push(item.specialEquipment.name);
			} else if (item.coTwoCategory === 17) {
				result.push(`${item.number} ${this.FormMSG(500, 'trips')}`);
			}

			const resultJoined = result.join(', ');
			return resultJoined === '' ? '-' : resultJoined;
		},
		async handleReviewDialogAutomationEnd({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i].verified = carbonData.verified;
					this.greenList[i].type = carbonData.type;
					this.greenList[i].coTwoCategory = carbonData.coTwoCategory;
					this.greenList[i].subType = carbonData.subType;
					this.greenList[i].additionalSubType = carbonData.additionalSubType;
					break;
				}
			}
		},
		handlerFilterBudgetAdvancedHaveFilter({ haveFilter }) {
			this.haveFilter = haveFilter;
		},
		async handleReviewDialogResetStatus({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i].verified = carbonData.verified;
					this.greenList[i].coTwoCategory = carbonData.coTwoCategory;
					break;
				}
			}
		},
		showResetStatusMenu(item) {
			if ((item.entityType === 1 || item.entityType === 8 || item.entityType === 7) && item.verified > 0) {
				return true;
			} else if ([2, 3, 4, 5, 6, 9, 10].includes(item.entityType) && item.verified !== 2) {
				return true;
			}

			return false;
		},
		async handleClickResetStatus(item, index) {
			let reviewStatusToSend = 0;
			const carbonId = +item.id;

			const resultVerifiedStatus = await changeCarbonVerifiedStatus([+carbonId], reviewStatusToSend);

			if (item.entityType === 1) {
				/*
        if (item.coTwoCategory === 4) {
          reviewStatusToSend = 2;
          this.greenList[index].verified = 2;
        } else { */
				if (resultVerifiedStatus.length === 0) {
					reviewStatusToSend = 0;
					this.greenList[index].verified = 0;
					this.greenList[index].coTwoCategory = 0;
				}

				//	}
			} else if (item.entityType === 8 || item.entityType === 7) {
				if (resultVerifiedStatus.length === 0) {
					reviewStatusToSend = 0;
					this.greenList[index].verified = 0;
					this.greenList[index].coTwoCategory = 0;
				}
			} else if ([2, 3, 4, 5, 6, 9, 10].includes(item.entityType)) {
				if (resultVerifiedStatus.length === 0) {
					reviewStatusToSend = 2;
					this.greenList[index].verified = reviewStatusToSend;
				}
			}

			this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(314, 'Updated status successfully!'));
		},
		async setStatusToReviewSelection() {
			const action = async () => {
				try {
					this.loadingActionSelection = true;

					let carbonIds = [];

					for (let i = 0; i < this.greenListToReview.length; i++) {
						const item = this.greenListToReview[i];

						carbonIds.push(+item.id);
					}

					this.resultChangeStatus = await changeCarbonVerifiedStatus(carbonIds, 2);

					for (let i = 0; i < this.greenList.length; i++) {
						const item = this.greenList[i];

						if (carbonIds.length === 0) {
							break;
						}

						let findIndex = carbonIds.findIndex((option) => option === +item.id);

						if (findIndex > -1) {
							let findIndexInResultChangeStatus = -1;

							if (this.resultChangeStatus.length > 0) {
								findIndexInResultChangeStatus = this.resultChangeStatus.findIndex((option) => +option.id === +item.id);
							}

							if (findIndexInResultChangeStatus === -1) {
								item.verified = 2;

								carbonIds.splice(findIndex, 1);
							}
						}
					}

					if (this.resultChangeStatus.length > 0) {
						this.showModalLogChangeStatus = true;
					}

					this.loadingActionSelection = false;

					if (this.resultChangeStatus.length === 0) {
						this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(389, 'Updated status of selection successfully!'));
					}
				} catch (e) {
					this.loadingActionSelection = false;
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
				}
			};

			this.confirmModal(action, this.FormMSG(798, "Confirm changing status of selected items to 'TO REVIEW'?"));
		},
		async resetStatusSelection() {
			const action = async () => {
				try {
					this.loadingActionSelection = true;

					let expenseCarbonIds = [];
					let externalCarbonIds = [];
					let othersCarbonIds = [];

					for (let i = 0; i < this.greenListToReview.length; i++) {
						const item = this.greenListToReview[i];

						if (item.entityType === 1) {
							expenseCarbonIds.push(+item.id);
						} else if (item.entityType === 8 || item.entityType === 7) {
							externalCarbonIds.push(+item.id);
						} else if ([2, 3, 4, 5, 6, 9, 10].includes(item.entityType)) {
							othersCarbonIds.push(+item.id);
						}
					}

					let resultExpenseChangeStatus = [];
					let resultExternalChangeStatus = [];
					let resultOtherChangeStatus = [];
					if (expenseCarbonIds.length > 0) {
						resultExpenseChangeStatus = await changeCarbonVerifiedStatus(expenseCarbonIds, 0);
					}

					if (externalCarbonIds.length > 0) {
						resultExternalChangeStatus = await changeCarbonVerifiedStatus(externalCarbonIds, 0);
					}

					if (othersCarbonIds.length > 0) {
						resultOtherChangeStatus = await changeCarbonVerifiedStatus(othersCarbonIds, 2);
					}

					let concatIds = _.concat([], expenseCarbonIds, externalCarbonIds, othersCarbonIds);

					for (let i = 0; i < this.greenList.length; i++) {
						const item = this.greenList[i];

						if (concatIds.length === 0) {
							break;
						}

						let findIndex = concatIds.findIndex((option) => option === +item.id);

						if (findIndex > -1) {
							if (item.entityType === 1) {
								let findIndex = -1;
								if (resultExpenseChangeStatus.length > 0) {
									findIndex = resultExpenseChangeStatus.findIndex((option) => +option.id === item.id);
								}

								if (findIndex === -1) {
									item.verified = 0;
									item.coTwoCategory = 0;
								}
							} else if (item.entityType === 8 || item.entityType === 7) {
								let findIndex = -1;
								if (resultExternalChangeStatus.length > 0) {
									findIndex = resultExternalChangeStatus.findIndex((option) => +option.id === item.id);
								}

								if (findIndex === -1) {
									item.verified = 0;
									item.coTwoCategory = 0;
								}
							} else if ([2, 3, 4, 5, 6, 9, 10].includes(item.entityType)) {
								let findIndex = -1;
								if (resultOtherChangeStatus.length > 0) {
									findIndex = resultOtherChangeStatus.findIndex((option) => +option.id === item.id);
								}

								if (findIndex === -1) {
									item.verified = 0;
									item.coTwoCategory = 0;
								}
								item.verified = 2;
							}

							concatIds.splice(findIndex, 1);
						}
					}

					this.resultChangeStatus = _.concat([], resultExpenseChangeStatus, resultExternalChangeStatus, resultOtherChangeStatus);

					if (this.resultChangeStatus.length > 0) {
						this.showModalLogChangeStatus = true;
					}

					this.loadingActionSelection = false;

					if (this.resultChangeStatus.length === 0) {
						this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(389, 'Updated status of selection successfully!'));
					}
				} catch (e) {
					this.loadingActionSelection = false;
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
				}
			};

			this.confirmModal(action, this.FormMSG(194, 'Are you sure to reset status of selection ?'));
		},
		async handleClickViewAutomation(templateId) {
			this.carbonTemplateData = await getCarbonTemplate(+templateId);

			this.showCarbonTemplateDialog = true;
		},
		handleCarbonTemplateDialogClose() {
			this.showCarbonTemplateDialog = false;
			this.carbonTemplateData = {};
		},
		async handleRunAutomationOptionDialogChoice({ choice }) {
			await this.runAutomationAction(choice);
		},
		getLabelVerifiedStatus(value) {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);
			return menues.find((option) => option.value === value).text;
		},
		async handleShowMore() {
			if (this.alreadyLastData === false) {
				this.offset += this.limit;
				await this.getGreenTableList(true, 'containerGreenTable');
			}
		},
		async handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;

			if (event.target.scrollTop) {
				this.resetLoadData = false;
			}
			if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
				if (!this.resetLoadData) {
					if (this.alreadyLastData === false) {
						this.offset += this.limit;
						await this.getGreenTableList(true, 'containerGreenList', false);
					}
				} else {
					this.resetLoadData = false;
				}
			}
		},
		async handleClickMarkReviewedSelections() {
			try {
				const action = async () => {
					try {
						this.loadingActionSelection = true;
						let listId = [];
						for (let i = 0; i < this.greenListToReview.length; i++) {
							listId.push(+this.greenListToReview[i].id);
						}
						this.resultChangeStatus = await changeCarbonVerifiedStatus(listId);

						for (let i = 0; i < this.greenList.length; i++) {
							const item = this.greenList[i];

							if (listId.length === 0) {
								break;
							}

							const findIndex = listId.findIndex((option) => option === +item.id);

							if (findIndex > -1) {
								let findIndexInResultStatus = -1;

								if (this.resultChangeStatus.length > 0) {
									findIndexInResultStatus = this.resultChangeStatus.findIndex((option) => +option.id === +item.id);
								}

								if (findIndexInResultStatus === -1) {
									item.verified = 3;

									listId.splice(findIndex, 1);
								}
							}
						}

						if (this.resultChangeStatus.length > 0) {
							this.showModalLogChangeStatus = true;
						}

						this.loadingActionSelection = false;

						if (this.resultChangeStatus.length === 0) {
							this.createToastForMobile(this.FormMSG(951, 'Success'), this.FormMSG(953, 'Data is reviewed!'));
						}
					} catch (e) {
						this.loadingActionSelection = false;
						this.stateError.msg = this.resolveGqlError(e);
						this.stateError.show = true;
					}
				};

				this.confirmModal(action, this.FormMSG(206, "Confirm changing status of selected items to 'REVIEWED'?"));
			} catch (e) {
				this.loadingForMarkReview = false;
				this.createToastForMobile(this.FormMSG(1612, 'Error'), this.FormMSG(1613, 'Error during update data'), '', 'danger');
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},
		async handleResetFilter() {
			this.filterInputModel = '';
			this.filter = '';

			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleResetSupplierKeyWordFilter() {
			this.filterSupplierKeyword = '';
			this.filterInputSupplierKeyword = '';

			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleResetCostCenterFilter() {
			this.filterCostCenter = '';
			this.filterInputCostCenter = '';

			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		resetOffsetFilter() {
			this.offset = 0;
			this.pagination.offset = 0;
			this.greenListToReview = [];
			this.resetLoadData = true;
			this.alreadyLastData = false;

			this.$refs['table-green'].$el.scrollTo(0, 0);
		},
		handleKeydowEnterFilter() {
			document.getElementById('groupAppendSearch').focus();
			// this.handleChangeFilter(this.filterInputModel);
		},
		async handleChangeFilter(payload) {
			this.filter = payload.trim();
			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleChangeSupplierFilter(payload) {
			this.filterSupplierKeyword = payload.trim();
			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleChangeCostCenterFilter(payload) {
			this.filterCostCenter = payload.trim();
			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleNewEntryOptionSuccess() {
			this.resetOffsetFilter();

			await this.getGreenTableList();
		},
		async handleNewEntryOptionReviewResultImport({ carbons }) {
			if (carbons.length > 0) {
				this.greenListToReview = carbons;
				const item = carbons[0];
				this.prepareDataToReview(item, 1);
				this.getIndexFromListToReview = true;
				this.currentIndexDataToReview = 1;
				this.lengthDataToReview = carbons.length;
				this.showReviewDialog = true;
			}
		},
		async handleViewLogAutomation() {
			this.showViewLogAutomation = false;
			this.logAutomation = {};

			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async handleClickRunAutomation() {
			this.showRunAutomationOption = true;
		},
		async runAutomationAction(choice) {
			this.loadingRunAutomation = true;

			let listId = [];
			if (this.greenListToReview.length > 0) {
				for (let i = 0; i < this.greenListToReview.length; i++) {
					const element = this.greenListToReview[i];
					listId.push(+element.id);
				}
			} else {
				listId = null;
			}

			this.logAutomation = await runAutomation(null, choice === 1, listId);
			this.showViewLogAutomation = true;

			this.loadingRunAutomation = false;
		},
		async checkIfCarbonTemplateExist() {
			this.carbonTemplateExist = await checkIfCarbonTemplateExist();
		},
		async handleClickDeleteExternalSources() {
			const action = async () => {
				try {
					let listId = [];
					this.loadingDeleteAction = true;
					for (let i = 0; i < this.greenListToReview.length; i++) {
						const element = this.greenListToReview[i];
						if (+element.entityType === 8) {
							listId.push(+element.entityId);
						}
					}

					if (listId.length > 0) {
						await delCarbonFromExternalSource(listId);
						this.greenList = this.greenList.filter((option) => !listId.includes(+option.entityId));
						this.greenListToReview = [];
						this.selectAll = false;
						this.greenList = this.greenList.map((option) => ({
							...option,
							checked: false
						}));
						this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(917, 'Externals deleted successfully!'));
						this.refreshTotalRecords();
					} else {
						this.createToastForMobile(
							this.FormMSG(98, 'Success'),
							this.FormMSG(1250, 'No external found'),
							this.FormMSG(1252, 'No external is found in the list'),
							'warning'
						);
						this.refreshTotalRecords();
					}
					this.loadingDeleteAction = false;
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
					this.loadingDeleteAction = false;
				}
			};

			this.confirmModal(action, this.FormMSG(1421, 'Only entries from external sources will be deleted. Are you sure you want to delete it?'));
		},
		handleChangePagination({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.getGreenTableList(true);
		},
		async handleClickDeleteExternal(item) {
			const action = async () => {
				await delCarbonFromExternalSource([+item.entityId]);

				this.greenList = this.greenList.filter((option) => option.id !== item.id);
				this.greenListToReview = this.greenListToReview.filter((option) => option.id !== item.id);
				this.totalRecord -= 1;
				this.pagination.offset -= 1;

				this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(121, 'External deleted successfully!'));
			};

			this.confirmModal(action, this.FormMSG(97, 'Are you sure ?'));
		},
		handleClickReviewSelection() {
			const item = this.greenListToReview[0];
			this.prepareDataToReview(item, 1);
			this.getIndexFromListToReview = true;
			this.currentIndexDataToReview = 1;
			this.lengthDataToReview = this.greenListToReview.length;
			this.showReviewDialog = true;
		},
		handleChangeItemChecked(payload, item, index) {
			let findIndex = -1;
			if (this.greenListToReview.length > 0) {
				findIndex = this.greenListToReview.findIndex((option) => parseInt(option.id, 10) === parseInt(item.id, 10));
			}

			if (findIndex > -1) {
				if (payload === false) {
					this.greenListToReview.splice(findIndex, 1);

					if (this.greenListToReview.length === 0) {
						this.selectAll = false;
					}
				}
			} else if (findIndex === -1) {
				if (payload === true) {
					this.greenListToReview.push({
						...item,
						indexFromList: index
					});
					this.selectAll = true;
				}
			}
		},
		handleInputSelectAll(value) {
			this.greenListToReview = [];

			this.greenList = this.greenList.map((option) => {
				const result = {
					...option,
					checked: value
				};
				if (value) {
					this.greenListToReview.push(result);
				}

				return result;
			});

			if (value) {
				if (this.pagination.offset < this.totalRecord) {
					this.showMultiSelectionConfirmDialog = true;
				}
			} else {
			}
		},
		handleReviewDialogReviewed({ currentIndex }) {
			this.greenListToReview[currentIndex].verified = 3;

			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +this.greenListToReview[currentIndex].id) {
					this.greenList[i].verified = 3;
					break;
				}
			}

			this.handleReviewDialogAfter({ currentIndex });
		},
		async handleReviewDialogSubmit({ carbonData }) {
			this.totalRecord += 1;
			// await this.getGreenTableList();

			this.greenList.unshift(carbonData);
		},
		async handleReviewDialogUpdateSuccess({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i] = carbonData;
					break;
				}
			}
		},
		handleReviewDialogAfter({ currentIndex }) {
			if (this.getIndexFromListToReview === false) {
				if (currentIndex + 1 === this.greenList.length) {
					const item = this.greenList[currentIndex];

					this.prepareDataToReview(item, currentIndex);
				} else {
					const item = this.greenList[currentIndex + 1];

					this.prepareDataToReview(item, currentIndex + 1);
				}
			} else {
				if (currentIndex + 1 === this.greenListToReview.length) {
					const item = this.greenListToReview[currentIndex];

					this.prepareDataToReview(item, currentIndex);
				} else {
					const item = this.greenListToReview[currentIndex + 1];

					this.prepareDataToReview(item, currentIndex + 1);
				}
			}
		},
		handleReviewDialogBefore({ currentIndex }) {
			if (this.getIndexFromListToReview === false) {
				const item = this.greenList[currentIndex - 1];

				this.prepareDataToReview(item, currentIndex - 1);
			} else {
				const item = this.greenListToReview[currentIndex - 1];

				this.prepareDataToReview(item, currentIndex - 1);
			}
		},
		handleClickNewEntry() {
			this.showNewEntryOptionDialog = true;
		},
		handleNewEntryOptionCreate() {
			this.showNewEntryOptionDialog = false;
			this.sourceType = 8;
			this.createNewGreen = true;
			this.dataToReview = null;
			this.showReviewDialog = true;
		},
		async handleClickReview(item, index) {
			await this.changeCheckedGreenList().then((res) => {
				this.prepareDataToReview(item, index);

				this.selectAll = false;
				this.greenListToReview = [{ ...item, indexFromList: index }];
				this.getIndexFromListToReview = true;
				this.currentIndexDataToReview = 1;
				this.lengthDataToReview = this.greenListToReview.length;
				this.showReviewDialog = true;

				this.greenList = res;
			});
		},
		changeCheckedGreenList() {
			return new Promise((resolve) => {
				if (this.selectAll === false) {
					resolve(this.greenList);
				} else {
					const result = this.greenList.map((option) => ({
						...option,
						checked: false
					}));

					resolve(result);
				}
			});
		},
		prepareDataToReview(item, index) {
			this.sourceType = parseInt(item.entityType, 10);

			this.currentIndexDataToReview = index + 1;
			this.createNewGreen = false;
			this.dataToReview = item;
		},
		async handleReviewDialogClose({ refreshList, currentIndex, carbonData }) {
			this.createNewGreen = false;
			this.sourceType = 1;
			this.dataToReview = {};
			this.currentIndexDataToReview = 0;
			// this.getIndexFromListToReview = false;
			// this.greenListToReview = []
			this.showReviewDialog = false;

			if (refreshList === true) {
				await this.getGreenTableList();
			}

			if (this.greenListToReview.length === 1) {
				if (!_.isNil(this.greenListToReview[0].indexFromList)) {
					this.greenList[this.greenListToReview[0].indexFromList].checked = false;
					this.greenListToReview = [];
					this.getIndexFromListToReview = false;
					this.selectAll = false;
				}
			} else {
				this.greenList = this.greenList.map((option) => {
					return {
						...option,
						checked: false
					};
				});
				this.greenListToReview = [];
				this.getIndexFromListToReview = false;
				this.selectAll = false;
			}
		},
		async handleFilterBudgetAdvancedChange(payload) {
			this.filterActive = payload;

			if (!_.isNil(payload.startDate)) {
				this.filterActive.startDate = payload.startDate.substring(0, payload.startDate.indexOf('T'));
			}

			if (!_.isNil(payload.endDate)) {
				this.filterActive.endDate = payload.endDate.substring(0, payload.endDate.indexOf('T'));
			}

			if (!payload.userDepartment) {
				this.filterActive.userDepartment = -1;
			}

			this.resetOffsetFilter();
			await this.getGreenTableList();
		},
		async refreshTotalRecords() {
			let reviewStatus = [];
			let arrayType = [];
			if (!_.isNil(this.filterGreenTable.reviewStatus) && this.filterGreenTable.reviewStatus.length > 0) {
				this.filterGreenTable.reviewStatus.map((option) => {
					reviewStatus.push({ status: +option });
				});
			}
			if (!_.isNil(this.filterGreenTable.arrayType) && this.filterGreenTable.arrayType.length > 0) {
				this.filterGreenTable.arrayType.map((option) => {
					arrayType.push({ type: +option });
				});
			}
			this.totalRecord = await getTotalNumberProjectCarbon({
				...this.filterGreenTable,
				reviewStatus,
				arrayType,
				keyWord: this.filter,
				costCenterKeyWord: this.filterCostCenter,
				supplierKeyWord: this.supplierKeyWord
			});

			this.setPaginationOffset();
		},
		async getGreenTableList(toPush = false, containerToLoaded = 'containerGreenList', initListToReview = true) {
			const actionForLoader = async () => {
				let reviewStatus = [];
				let arrayType = [];
				this.getIndexFromListToReview = false;

				initListToReview && (this.greenListToReview = []);

				//console.log('GET GREEN TABLE LIST ', this.filterGreenTable);
				if (!_.isNil(this.filterGreenTable.reviewStatus) && this.filterGreenTable.reviewStatus.length > 0) {
					this.filterGreenTable.reviewStatus.map((option) => {
						reviewStatus.push({ status: +option });
					});
				}

				if (!_.isNil(this.filterGreenTable.arrayType) && this.filterGreenTable.arrayType.length > 0) {
					this.filterGreenTable.arrayType.map((option) => {
						arrayType.push({ type: +option });
					});
				}

				let newFilterActive = {
					...this.filterGreenTable,
					recordOffSet: this.offset,
					recordLimit: this.limit,
					keyWord: this.filter,
					costCenterKeyWord: this.filterCostCenter,
					supplierKeyWord: this.filterSupplierKeyword
				};

				if (reviewStatus.length > 0) {
					newFilterActive = _.assign(newFilterActive, {
						reviewStatus
					});
				}

				if (arrayType.length > 0) {
					newFilterActive = _.assign(newFilterActive, {
						arrayType
					});
				}

				this.totalRecord = await getTotalNumberProjectCarbon({
					...this.filterGreenTable,
					reviewStatus,
					arrayType,
					keyWord: this.filter,
					costCenterKeyWord: this.filterCostCenter,
					supplierKeyWord: this.supplierKeyWord
				});

				//console.log('newFilterActive', newFilterActive);

				if (toPush === false) {
					this.pagination.offset = 0;
					this.setPaginationOffset();

					this.greenList = await getProjectCarbon(newFilterActive);
					//console.log('getProject CARbon', this.greenList);
					this.greenList = this.greenList.map((option) => {
						return {
							...option,
							setNameLocation: ''
						};
					});
				} else if (toPush === true) {
					this.setPaginationOffset();
					const res = await getProjectCarbon(newFilterActive);

					if (res.length > 0) {
						this.greenList = _.concat(this.greenList, res);
					} else {
						if (this.pagination.offset === this.totalRecord) {
							this.alreadyLastData = true;
						}
					}
				}
			};

			this.$nextTick(async () => {
				// console.log(this.$refs[containerToLoaded]);
				await this.showLoader(actionForLoader, containerToLoaded);
			});
		},
		setPaginationOffset() {
			if (this.totalRecord < LIMIT) {
				this.pagination.offset = this.totalRecord;
			} else {
				if (this.pagination.offset + LIMIT > this.totalRecord) {
					this.pagination.offset = this.totalRecord;
				} else {
					this.pagination.offset += LIMIT;
				}
			}
		},
		async showPicture(carbonId) {
			let files = await getCarbonFiles(carbonId);
			// console.log({ files });
			files = files.map((file) => ({
				xid: getFileName(file),
				src: `${process.env.VUE_APP_GQL}/images/${file}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + file,
				ext: getFileExtension(file)
			}));

			this.$previewImages({
				images: files,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc',
					hideDeleteButton: true
				}
			});
		},
		provideFilmVehicleData(item, info = 'resume') {
			const data = {
				value: 0,
				unity: null,
				info: null,
				name: null
			};
			if ([1, 2, 3, 4].includes(item.type)) {
				data.value = item.km;
				data.unity = this.FormMSG(521, 'km');
				data.info = this.FormMSG(539, 'Distance');
			} else {
				data.value = item.fuelConsumption;
				data.unity = this.FormMSG(514, 'L');
				data.info = this.FormMSG(540, 'Fuel consumption');
			}

			if (info === 'resume') return `${formatLocalString(data.value)} ${data.unity}`;
			else {
				data.name = this.GetTextFromMenuNumberAndMenuValue(1447, item.type);

				return `
					<b>${this.FormMSG(542, 'Vehicle type')}: </b> ${data.name} </br>
					<b>${data.info}</b>: ${formatLocalString(data.value)} ${data.unity}
				`;
			}
		}
	}
};
</script>

<style scoped>
.thead-dark th {
	vertical-align: text-top;
}
</style>
