var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", { staticStyle: { position: "relative" } }, [
          _c(
            "div",
            {
              class:
                _vm.$screen.width > 576
                  ? "container-layout"
                  : "container-mobile",
            },
            [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "h1",
                          {
                            class: [
                              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                              { "is-pwa": _vm.$isPwa() },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.FormMSG(18, "Green table")))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { ref: "containerGreenList" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "p-0" },
                            [
                              _c(
                                "b-card",
                                { attrs: { "no-body": "" } },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-alert",
                                                {
                                                  attrs: {
                                                    show: _vm.stateError.show,
                                                    variant: "danger",
                                                    dismissible: "",
                                                  },
                                                  on: {
                                                    dismissed:
                                                      _vm.handleDismissedError,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.stateError.msg
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-alert",
                                                {
                                                  attrs: {
                                                    show: _vm.projectEnded,
                                                    variant: "warning",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "AlertTriangle"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          841,
                                                          "Project end date has passed "
                                                        )
                                                      ) +
                                                      " (" +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          _vm.endDateProject,
                                                          "DD-MM-YYYY"
                                                        )
                                                      ) +
                                                      ")\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c("filter-budget-advanced", {
                                                attrs: {
                                                  "hide-expense-details-section":
                                                    "",
                                                  "hide-supplier-section": "",
                                                  "hide-person-section": "",
                                                  "hide-entry-detail-section": false,
                                                  "hide-button-export": "",
                                                  "hide-button-refresh-data":
                                                    "",
                                                  "label-expense-details":
                                                    _vm.FormMSG(
                                                      148,
                                                      "Expense details"
                                                    ),
                                                  "filter-type":
                                                    _vm.FILTER_TYPE.GREEN_TABLE,
                                                  "is-carbon": true,
                                                },
                                                on: {
                                                  "filter-budget-advanced:have-filter":
                                                    _vm.handlerFilterBudgetAdvancedHaveFilter,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.carbonTemplateExist === true
                                        ? _c(
                                            "b-row",
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "float-right",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant:
                                                            "custom-primary-blue",
                                                          disabled:
                                                            _vm.loadingRunAutomation,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.handleClickRunAutomation,
                                                        },
                                                      },
                                                      [
                                                        _vm.loadingRunAutomation
                                                          ? _c("b-spinner", {
                                                              staticClass:
                                                                "mr-3",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Play"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                197,
                                                                "Run automations"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex row align-items-center mt-3 hide-on-tablet",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { md: "12" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-between",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row pl-3",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-color-australien fs-16 fw-600",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2 pt-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t0 - " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .pagination
                                                                                .offset
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                519,
                                                                                "on"
                                                                              )
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              _vm.totalRecord
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                520,
                                                                                "records"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm
                                                                      .pagination
                                                                      .offset ===
                                                                    _vm.totalRecord,
                                                                  variant:
                                                                    "outline-primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleShowMore()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        954,
                                                                        "Load More"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _vm.projectEnded ===
                                                            false
                                                              ? _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.handleClickNewEntry,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Plus"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            547,
                                                                            "New entry"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "custom-primary-blue",
                                                                  disabled:
                                                                    _vm
                                                                      .greenListToReview
                                                                      .length ===
                                                                    0,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.handleClickReviewSelection,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "Check"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 16,
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        548,
                                                                        "Review selection"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              ref: "containerGreenTable",
                                              attrs: {
                                                id: "containerGreenTable",
                                              },
                                            },
                                            [
                                              _vm.$screen.width >= 992
                                                ? _c("b-table", {
                                                    ref: "table-green",
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      "selected-variant":
                                                        "primary",
                                                      hover: "",
                                                      selectable: "",
                                                      "select-mode": "single",
                                                      responsive: "sm",
                                                      "sticky-header": "700px",
                                                      items: _vm.greenList,
                                                      fields:
                                                        _vm.greenTableFields,
                                                      bordered: "",
                                                      striped: "",
                                                      small: "",
                                                      "head-variant": "dark",
                                                      "empty-text": _vm.FormMSG(
                                                        250,
                                                        "No data found"
                                                      ),
                                                      "show-empty": "",
                                                      "tbody-tr-class": "p-2",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "head(actionSelected)",
                                                          fn: function ({}) {
                                                            return [
                                                              _vm.greenList
                                                                .length > 0
                                                                ? _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        id: "selectAllGreenList",
                                                                        size: "lg",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleInputSelectAll,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.selectAll,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.selectAll =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "selectAll",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "head(description)",
                                                          fn: function (data) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.label
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm.$screen
                                                                    .width >=
                                                                  992
                                                                    ? _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  id: "filterInput",
                                                                                  placeholder:
                                                                                    _vm.FormMSG(
                                                                                      37,
                                                                                      "Type to Search"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.handleChangeFilter,
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.handleKeydowEnterFilter.apply(
                                                                                      null,
                                                                                      arguments
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.filterInputModel,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.filterInputModel =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "filterInputModel",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            {
                                                                              staticClass:
                                                                                "cursor-pointer",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-search-filter",
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .filter
                                                                                    .length ===
                                                                                  0
                                                                                    ? _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Search"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "X"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              _vm.handleResetFilter,
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "head(supplier)",
                                                          fn: function (data) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.label
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm.$screen
                                                                    .width >=
                                                                  992
                                                                    ? _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  id: "filterInput",
                                                                                  placeholder:
                                                                                    _vm.FormMSG(
                                                                                      37,
                                                                                      "Type to Search"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.handleChangeSupplierFilter,
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.handleKeydowEnterFilter.apply(
                                                                                      null,
                                                                                      arguments
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.filterInputSupplierKeyword,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.filterInputSupplierKeyword =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "filterInputSupplierKeyword",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            {
                                                                              staticClass:
                                                                                "cursor-pointer",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-search-filter",
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .filterSupplierKeyword
                                                                                    .length ===
                                                                                  0
                                                                                    ? _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Search"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "X"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              _vm.handleResetSupplierKeyWordFilter,
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "head(costCenter)",
                                                          fn: function (data) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.label
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm.$screen
                                                                    .width >=
                                                                  992
                                                                    ? _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  id: "filterInput",
                                                                                  placeholder:
                                                                                    _vm.FormMSG(
                                                                                      37,
                                                                                      "Type to Search"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.handleChangeCostCenterFilter,
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.handleKeydowEnterFilter.apply(
                                                                                      null,
                                                                                      arguments
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.filterInputCostCenter,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.filterInputCostCenter =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "filterInputCostCenter",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            {
                                                                              staticClass:
                                                                                "cursor-pointer",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-search-filter",
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .filterCostCenter
                                                                                    .length ===
                                                                                  0
                                                                                    ? _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Search"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "X"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          staticClass:
                                                                                            "icon",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#000000",
                                                                                              size: 16,
                                                                                              "stroke-width": 2.5,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              _vm.handleResetCostCenterFilter,
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(verified)",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "status fw-700",
                                                                      class: {
                                                                        validated:
                                                                          item.verified ===
                                                                          3,
                                                                        "not-submitted":
                                                                          item.verified ===
                                                                          0,
                                                                        pending:
                                                                          item.verified ===
                                                                          2,
                                                                        info:
                                                                          item.verified ===
                                                                          1,
                                                                      },
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.7rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-center",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.getLabelVerifiedStatus(
                                                                                  item.verified
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(actionSelected)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleChangeItemChecked(
                                                                          $event,
                                                                          data.item,
                                                                          data.index
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      data.item
                                                                        .checked,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          data.item,
                                                                          "checked",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.item.checked",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(date)",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatDate"
                                                                      )(
                                                                        item.date,
                                                                        "DD/MM/YYYY"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(amount)",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatCurrency"
                                                                      )(
                                                                        item.amount,
                                                                        "DD/MM/YYYY"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(coTwo)",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatRendKg"
                                                                      )(
                                                                        item.coTwo
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(coTwoDetails)",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex",
                                                                      staticStyle:
                                                                        {
                                                                          "padding-top":
                                                                            "2px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm.getValueToolTipCoTwoDetails(
                                                                        item,
                                                                        index
                                                                      ) !== ""
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              item.coTwoCategory >
                                                                              0
                                                                                ? _c(
                                                                                    "button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "btn-transparent text-color-blue-streak",
                                                                                      attrs:
                                                                                        {
                                                                                          id: `popover-details-carbon-${item.id}`,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Info"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              size: 16,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getValueCoTwoDetails(
                                                                                item
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm.getValueToolTipCoTwoDetails(
                                                                    item,
                                                                    index
                                                                  ) !== "" &&
                                                                  item.coTwoCategory >
                                                                    0
                                                                    ? _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `popover-details-carbon-${item.id}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                index ===
                                                                                  0 ||
                                                                                index ===
                                                                                  1 ||
                                                                                index ===
                                                                                  2
                                                                                  ? "left"
                                                                                  : "top",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "title",
                                                                                  fn: function () {
                                                                                    return [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              "Info"
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  size: 16,
                                                                                                  color:
                                                                                                    "#225cbd",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  1547,
                                                                                                  "Information"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                  proxy: true,
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      _vm.getValueToolTipCoTwoDetails(
                                                                                        item,
                                                                                        index
                                                                                      )
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(option)",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-center dropdown-custom",
                                                                  staticStyle: {
                                                                    "min-width":
                                                                      "60px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-dropdown",
                                                                    {
                                                                      staticClass:
                                                                        "btn-transparent",
                                                                      attrs: {
                                                                        id: "dropdown-green-action",
                                                                        "no-caret":
                                                                          "",
                                                                        dropleft:
                                                                          "",
                                                                        boundary:
                                                                          "window",
                                                                        variant:
                                                                          "none",
                                                                        size: "sm",
                                                                        lazy: "",
                                                                      },
                                                                      on: {
                                                                        toggle:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleClickContextTable(
                                                                              item,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "button-content",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "MoreVertical"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleClickReview(
                                                                                  item,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-color-rhapsody-in-blue",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "CheckCircle"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      259,
                                                                                      "Review"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.showResetStatusMenu(
                                                                        item
                                                                      ) === true
                                                                        ? _c(
                                                                            "b-dropdown-item",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleClickResetStatus(
                                                                                      item,
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-color-rhapsody-in-blue",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "RotateCcw"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          289,
                                                                                          "Reset status"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.templateId >
                                                                      0
                                                                        ? _c(
                                                                            "b-dropdown-item",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleClickViewAutomation(
                                                                                      item.templateId
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-color-rhapsody-in-blue",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "Regex"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          291,
                                                                                          "View automation"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.haveDocument
                                                                        ? _c(
                                                                            "b-dropdown-item",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.showPicture(
                                                                                      +item.id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-color-rhapsody-in-blue",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "FileImage"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          252,
                                                                                          "See picture"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.entityType ===
                                                                      8
                                                                        ? _c(
                                                                            "b-dropdown-item",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleClickDeleteExternal(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-color-burning-tomato",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "Trash2"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          260,
                                                                                          "Remove"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3885599723
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        size: "md",
                                                        variant:
                                                          "custom-outline-gray",
                                                        disabled:
                                                          _vm.deleteDisabled ||
                                                          _vm.loadingDeleteAction,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.handleClickDeleteExternalSources,
                                                      },
                                                    },
                                                    [
                                                      _vm.loadingDeleteAction
                                                        ? _c("b-spinner", {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              158,
                                                              "Delete"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-dropdown",
                                                    {
                                                      attrs: {
                                                        id: "dropdown-action-selection",
                                                        variant:
                                                          "outline-primary",
                                                        disabled:
                                                          _vm.greenListToReview
                                                            .length === 0 ||
                                                          _vm.loadingActionSelection,
                                                        lazy: "",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "button-content",
                                                          fn: function () {
                                                            return [
                                                              _vm.loadingActionSelection
                                                                ? _c(
                                                                    "b-spinner",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      569,
                                                                      "Change selection status"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ]),
                                                    },
                                                    [
                                                      _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.resetStatusSelection,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "RotateCcw"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        612,
                                                                        "Reset selection status"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.setStatusToReviewSelection,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex text-color-rhapsody-in-blue",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "UserCheck"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        613,
                                                                        "Mark selection as TO REVIEW"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.handleClickMarkReviewedSelections,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex text-color-rhapsody-in-blue",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Check"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        614,
                                                                        "Mark selection as REVIEWED"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("review-dialog", {
                attrs: {
                  open: _vm.showReviewDialog,
                  "for-create": _vm.createNewGreen,
                  source: _vm.sourceType,
                  "data-to-review": _vm.dataToReview,
                  "data-length": _vm.lengthDataToReview,
                  "current-index": _vm.currentIndexDataToReview,
                },
                on: {
                  "review-dialog:before": _vm.handleReviewDialogBefore,
                  "review-dialog:after": _vm.handleReviewDialogAfter,
                  "review-dialog:submit": _vm.handleReviewDialogSubmit,
                  "review-dialog:update-success":
                    _vm.handleReviewDialogUpdateSuccess,
                  "review-dialog:reviewed": _vm.handleReviewDialogReviewed,
                  "review-dialog:automation-end":
                    _vm.handleReviewDialogAutomationEnd,
                  "review-dialog:reset-status":
                    _vm.handleReviewDialogResetStatus,
                  "review-dialog:close": _vm.handleReviewDialogClose,
                },
              }),
              _c("carbon-template-dialog", {
                attrs: {
                  open: _vm.showCarbonTemplateDialog,
                  "data-to-edit": _vm.carbonTemplateData,
                },
                on: {
                  "carbon-template-dialog:close":
                    _vm.handleCarbonTemplateDialogClose,
                },
              }),
              _c("view-log-automation-dialog", {
                attrs: {
                  open: _vm.showViewLogAutomation,
                  "log-data": _vm.logAutomation,
                },
                on: {
                  "view-log-automation:close": _vm.handleViewLogAutomation,
                },
              }),
              _c("run-automation-option-dialog", {
                attrs: { open: _vm.showRunAutomationOption },
                on: {
                  "run-automation-option-dialog:close": function ($event) {
                    _vm.showRunAutomationOption = false
                  },
                  "run-automation-option-dialog:choice":
                    _vm.handleRunAutomationOptionDialogChoice,
                },
              }),
              _c("new-entry-option-dialog", {
                attrs: { open: _vm.showNewEntryOptionDialog },
                on: {
                  "new-entry-option:close": function ($event) {
                    _vm.showNewEntryOptionDialog = false
                  },
                  "new-entry-option:create": _vm.handleNewEntryOptionCreate,
                  "new-entry-option:success": _vm.handleNewEntryOptionSuccess,
                  "new-entry-option:review-result-import":
                    _vm.handleNewEntryOptionReviewResultImport,
                },
              }),
              _c("multi-selection-confirm-dialog", {
                attrs: {
                  open: _vm.showMultiSelectionConfirmDialog,
                  "total-data-number": _vm.totalRecord,
                  "total-data-number-selected": _vm.pagination.offset,
                },
                on: {
                  "multi-selection-confirm-dialog:select-all":
                    _vm.handleMultiSelectionConfirmDialogSelectAll,
                  "multi-selection-confirm-dialog:cancel-selection":
                    _vm.handleMultiSelectionConfirmDialogCancelSelection,
                  "multi-selection-confirm-dialog:close":
                    _vm.handleMultiSelectionConfirmDialogClose,
                },
              }),
              _c("log-change-status-dialog", {
                attrs: {
                  open: _vm.showModalLogChangeStatus,
                  "data-list": _vm.resultChangeStatus,
                },
                on: {
                  "log-change-status-dialog:close":
                    _vm.handleLogChangeStatusDialogClose,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }