<template>
	<div>
		<b-row>
			<b-col sm="12" md="9" lg="9" xl="9">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(52, 'Category')">
							<b-form-input :value="categoryLabel" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(53, 'Number of persons')">
							<b-form-input class="bg-color-grams-hair" :value="numberOfPersonsValue" disabled />
						</b-form-group>
					</b-col>
					<b-col v-if="accomodation.category === 0" sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(54, 'Dates')">
							<b-form-input class="bg-color-grams-hair" :value="datesValue" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group v-if="accomodation.category === 0" :label="FormMSG(55, 'Accomodations name')">
							<b-form-input class="bg-color-grams-hair" :value="nameValue" disabled />
						</b-form-group>
						<b-form-group v-if="accomodation.category === 1" :label="FormMSG(54, 'Dates')">
							<b-form-input class="bg-color-grams-hair" :value="datesValue" disabled />
						</b-form-group>
					</b-col>
					<b-col v-if="accomodation.category === 0" sm="12" md="8" lg="8" xl="8">
						<b-form-group :label="FormMSG(56, 'Address')">
							<b-form-input class="bg-color-grams-hair" :value="addressValue" disabled />
						</b-form-group>
					</b-col>
					<b-col v-if="accomodation.category === 1" sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(67, 'Supplier')">
							<b-form-input class="bg-color-grams-hair" :value="supplierValue" disabled />
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import moment from 'moment';
import addressHelperMixin from '@/components/LocationService/addressHelper.mixin';

export default {
	name: 'GreenAccomodationForm',

	mixins: [languageMessages, globalMixin, addressHelperMixin],

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			accomodation: {
				category: 0,
				startDate: new Date(),
				endDate: new Date()
			}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				this.accomodation = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		categoryLabel() {
			const menues = this.FormMenu(1403);

			const result = _.find(menues, (option) => option.value === this.accomodation.category);

			if (!_.isNil(result)) {
				return result.text;
			}

			return '-';
		},
		datesValue() {
			return (
				moment(new Date(this.accomodation.startDate)).format('DD/MM/YYYY') + ' - ' + moment(new Date(this.accomodation.endDate)).format('DD/MM/YYYY')
			);
		},
		nameValue() {
			return this.accomodation.name ? this.accomodation.name : '-';
		},
		addressValue() {
			return this.accomodation.address ? this.rendAddress(this.accomodation.address) : '-';
		},
		supplierValue() {
			return this.accomodation.supplier ? this.accomodation.supplier.name : '-';
		},
		numberOfPersonsValue() {
			return this.accomodation.accomodationDeliveryList ? this.accomodation.accomodationDeliveryList.length : 0;
		}
	}
};
</script>

<style scoped></style>
